.site-map h3{
    margin-bottom: 20px;
}
.site-map .footer-menu-outer{
    height: auto;
    width: 100%;
    border: none!important;
    margin-bottom: 20px;
}
@media screen and (max-width: 575px) {
    .site-map{
        text-align: center;
    }
    .site-map h4{
        font-size: 18px;
    }
}