.monthly-price{
    padding: 20px 30px;
    border: 1px solid #edeeef;
    background: #fff;
    border-radius: 8px;;
    overflow: hidden;
}
.monthly-price strong{
    display: block;
    font-size: 26px;
    color: #1cb580;
}
.monthly-price .signin {
    display: inline-block;
    font-size: 25px;
    padding: 5px 0 0 0;
    margin: 0;
    background: none;
    border: none;
    color: #1cb580;
    font-weight: bold;
    text-decoration: underline;
}
.monthly-price .signin:hover {
    text-decoration: none;
}
.monthly-price span{
    font-size: 18px;
    clear: both;
    display: block;
    padding-bottom: 20px;
}
@media screen and (max-width: 767px) {
    .monthly-price{
        text-align: center;
    }
}