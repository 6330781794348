.categories{
    padding: 20px;
    border: 1px solid #edeeef;
    background: #fff;
    border-radius: 8px;;
}
.categories h3{
    font-size: 18px;
    color: #0385c7;
}
.category-list li a{
    font-size: 12px;
    color: #666666;
    margin: 5px 0;
    display: inline-block;
}
.category-list li a:hover{
    text-decoration: underline;
}