/*react-responsive-modal-modal*/
#profile-popup{
    padding: 0;
    margin: 15px;
    width: calc(100% - 30px);
    max-width: 600px;
    border-radius: 8px;
}
#profile-popup h3{
    margin: 0;
    padding: 20px 20px 12px;
}
#profile-popup .edit-profilt{
    min-height: 100px;
    padding: 50px;
}
#profile-popup .edit-profilt .user-profile p{
    display: none;
}
#profile-popup .edit-profilt .edit-button{
    display: none;
}
#profile-popup .edit-profilt .user-profile .upload-button{
    display: block;
}
#profile-popup .profile-form{
    padding: 40px;
}
#profile-popup .profile-form .error{
    position: relative;
}
#profile-popup .profile-form label{
    display: block;
    font-size: 12px;
    color: #c2c2c2;
}
#profile-popup .profile-form .form-control{
    display: block;
    font-size: 12px;
    color: #999;
}
#profile-popup .profile-form .btn-outer{
    text-align: right;
}
#profile-popup .profile-form .btn-outer .button{
    margin-left: 10px;
    border-radius: 5px;
}
#profile-popup .react-responsive-modal-closeButton{
    display: none;
}
#profile-popup .user-profile .edit-pic{
    position: absolute;
    width: 45px;
    height: 45px;
    background: rgba(0, 0, 0, 0.7);
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
#profile-popup .user-profile .edit-pic:hover{
    background: rgba(0, 0, 0, 0.8);
}
#profile-popup .user-profile .edit-pic input[type="file"]{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 9;
}
#profile-popup .user-profile .edit-pic .camra{
    color: #fff;
}
@media screen and (max-width: 767px) {
    #profile-popup .edit-profilt {
        min-height: 100px;
        padding: 40px 10px;
    }
    #profile-popup .profile-form {
        padding: 20px;
    }
    #profile-popup .profile-form .btn-outer {
        text-align: center;
    }
    #profile-popup .profile-form .btn-outer .button {
        margin: 5px;
    }
}