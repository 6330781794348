.testimonial-outer{
    padding: 10px 0 60px;
    overflow: hidden;
}
.testimonial-slider .slick-list{
    margin: 0 -15px;
}
.testimonial-slider .slick-list .slick-slide .item{
    padding: 0 15px;
    margin: 0 0 20px 0;
}
.testimonial-slider .slick-next,.testimonial-slider .slick-prev{
    display: none!important;
}
.testimonial-box{
    display: flex!important;
    background: #f4f5f7;
    padding: 20px;
    border-radius: 15px;
}
.testimonial-box .img{
    width: 80px;
    height: 80px;
    margin-right: 30px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
}
.testimonial-box .testimonial-text{
    flex: 1;
}
.testimonial-box .testimonial-text strong{
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    color: #333;
}
.testimonial-box .testimonial-text span{
    display: block;
    font-size: 14px;
    font-style: italic;
}
@media screen and (max-width: 1199px) {
    .testimonial-outer{
        padding-bottom: 10px;
    }
    .testimonial-outer .slick-dots{
        display: none!important;
    }
}
@media screen and (max-width: 575px) {
    .testimonial-box{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;   
    }
}