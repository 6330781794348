.blog-list{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}
.blog-list li{
    width: calc(33.33% - 20px);
    margin: 0 10px 20px 10px;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 10px #ccc;
    transition: all .3s ease;
    transform: translateY(0);
}
.blog-list li .blog-list-img{
    background-repeat: no-repeat;
    background-size: cover;
}
@media screen and (min-width: 1200px) {
    .blog-list li .blog-list-img{
        min-height: 160px;
    }
}
@media screen and (max-width: 1199px) {
    .blog-list li .blog-list-img{
        min-height: 140px;
    }
}

.blog-list li:hover{
    transform: translateY(-5px);
}
.blog-list li .text{
    padding: 20px;
}
.blog-list li .text h4{
    font-size: 18px;
    color: #0385c7;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 10px;
}
.blog-list li .text p{
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #333;
}
@media screen and (max-width: 991px) {
    .blog-list li {
        width: calc(50% - 20px);
    }
}
@media screen and (max-width: 575px) {
    .blog-list li {
        width: calc(100% - 20px);
    }
}