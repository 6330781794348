.contact-map{
    border-radius: 5px;
    box-shadow: 0 0 10px #ccc;
    overflow: hidden;
}
.contact-form{
    background: #ebf1f6;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px #ccc;
}
.contact-form label{
    color: #999;
}
.contact-form .btn-outer{
    text-align: center;
}