.d-top-strip .top-strip{
  display: block;
}
.top-strip{
  background: #f0c139;
  padding: 10px 0;
  text-align: center;
  width: 100%;
  display: none;
}
.top-strip p{
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top-strip p span{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}
.top-strip p a{
  text-decoration: underline;
  color: #0385c7;
  margin-left: 5px;
  white-space: nowrap;
}
.top-strip p a:hover{
  text-decoration: none;
}
@media screen and (max-width: 991px) {
  .top-strip{
    padding: 6px;
  }
}