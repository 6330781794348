.footer-top{
  background: #fff;
  border-top: 1px solid #edeeef;
}
.footer-top-inner{
  max-width: 800px;
  margin: 0 auto;
}
.footer-top h3{
  text-align: center;
  max-width: 600px;
  margin: 0 auto 60px;
  color: #333;
}
.footer-top h4{
  color: #0385c7;
  font-size: 14px;
}
.footer-business{
  border-top: 1px solid #edeeef;
  padding-top: 40px;
}
.footer-business .footer-menu{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.footer-business .footer-menu li{
  width: 33.33%;
  padding: 0 15px 0 0;
}
.footer-menu-outer{
  border-right: 1px solid #edeeef;
    width: 200px;
    margin-bottom: 50px;
    height: calc(100% - 50px);
}
.footer-top-inner .row .col-md-4:nth-child(3n) .footer-menu-outer{
  border-right: none;
}
.footer-menu li a{
  font-size: 14px;
  color: #707070;
  margin: 5px 0;
  display: inline-block;
}
.footer-menu li a:hover{
  color: #0385c7;
}
.footer-bottom {
  background: #0385c7;
  padding: 40px 0;
  color: #fff;
}
.footer-logo img{
  max-height: 32px;
}
.footer-comparison p{
  font-size: 12px;
}
.footer-comparison p span{
  width: 100%;
  display: block;
}
.footer-comparison p a{
  color: #fff;
  clear: both;
}
.footer-comparison p a:hover{
  text-decoration: underline;
}
.compa-link{
  border: 1px solid #fff;
  font-size: 12px;
  padding: 8px 20px;
  color: #fff;
  float: right;
}
.compa-link:hover{
  background: #fff;
  color: #0385c7;
}
.footer-socials{
  text-align: center;
}
.footer-socials a{
  color: #fff;
  margin: 15px;
  display: inline-block;
  font-size: 18px;
  transition: all .3s ease;
  transform: translateY(0);
}
.footer-socials a:hover{
  transform: translateY(-3px);
}
.copy-right{
  text-align: center;
}
.copy-right p{
  font-size: 9px;
}

@media screen and (max-width: 767px) {
  .footer-menu-outer {
    width: 100%;
    padding-right: 15px;
  }
  .footer-business .footer-menu li{
    width: 50%;
  }
  .footer-top-inner .row .col-md-4:nth-child(3n) .footer-menu-outer{
    border-right: 1px solid #edeeef;
  }
  .footer-top-inner .row .col-sm-6:nth-child(2n) .footer-menu-outer{
    border-right: none;
  }
  .compa-link {
    float: none;
    display: inline-block;
    margin-top: 10px;
  }
}
@media screen and (max-width: 575px) {
  .footer-top h3{
    margin: 0 auto 20px;
  }
  .footer-menu-outer {
    border: none!important;
    border-bottom: 1px solid #edeeef!important;
    width: 100%;
    margin-bottom: 10px;
    padding-right: 0;
    padding-bottom: 20px;
    height: calc(100% - 20px);
    text-align: center;
  }
  .footer-business {
    border-top: none;
    padding-top: 0px;
    text-align: center;
  }
  .footer-business .footer-menu li{
    width: 100%;
    padding: 0;
  }
  .footer-bottom{
    text-align: center;
    padding: 20px 0;
  }
  .footer-bottom .text-right{
    text-align: center!important;
  }
  .footer-bottom .logo{
    display: inline-block;
    margin-bottom: 20px;
  }
  .footer-comparison{
    margin: 10px 0;
  }
}