.compare-table-outer.mobile-view{
    display: none!important;
}
.compare-table th{
    padding: 20px 15px;
    text-align: center;
    border-bottom: 1px solid #b3b3b3;
}
.compare-table td{
    font-size: 15px;
    line-height: 1.3;
    border-right: 1px solid #b3b3b3;
    padding: 20px 15px;
    text-align: center;
    min-width: 150px;
    max-width: 150px;
}
.compare-table tr:nth-child(odd) {
    background: #f4f5f7;
}
.compare-table tr:nth-child(odd):first-child {
    background: none;
}
.compare-table td:first-child{
    border-left: 1px solid #b3b3b3;
    background: #1cb580;
    color: #fff;
    min-width: 250px;
    max-width: 250px;
    text-align: left;
    padding-left: 40px;
}
.compare-table td.drak-gray{
    background: #4d4d4d;
}
.compare-table tr:nth-child(odd) td:first-child{
    background: #1b9b6a;
}
.compare-table tr:last-child{
    background: none;
}
.compare-table tr.gray-bg{
    background: #b3b3b3!important;
}
.compare-table tr.gray-bg td{
    background: none!important;
    padding: 0;
}
.compare-table tr.gray-bg td span{
    color: #fff;
    position: relative;
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 15px;
    cursor: pointer;
}
.compare-table tr.gray-bg td span:hover{
    text-decoration: none;
    background: #999999;
}
.compare-table tr.gray-bg td span::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    /*right: 0;*/
    top: 50%;
    margin-top: -4px;
    margin-left: 8px;
    border-right: 2px solid;
    border-bottom: 2px solid;
    transform: rotate(45deg);
    transition: transform .3s;
}
.compare-table tr.gray-bg td span.active::after {
    transform: rotate(-135deg);
    margin-top: 0;
}
.compare-table tr:last-child td{
    border-top: 1px solid #b3b3b3;
    border-left: none;
    border-right: none;
    background: #fff!important;
}
.compare-table tr td .m-box{
    padding: 20px 0;
    text-align: left;
    position: relative;
}
.compare-table tr td .m-box small{
    position: absolute;
    top: -2px;
    left: 0;
    text-decoration: line-through;
    color: #ff5722;
}
.compare-table tr td .m-box strong{
    display: block;
    font-size: 19px;
    line-height: 1.2;
    color: #1cb580;
    padding: 0;
}
.compare-table tr td .m-box .signin{
    display: inline-block;
    font-size: 21px;
    line-height: 1.2;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    color: #1cb580;
    font-weight: bold;
    text-decoration: underline;
}
.compare-table tr td .m-box .signin:hover{
    text-decoration: none;
}
.compare-table tr td .m-box span{
    font-size: 13px;
    padding: 6px 0 20px;
    clear: both;
    display: block;
}
.rating-icons{
    display: inline-flex;
    height: 21px;
    width: 106px;
}
/* popover css here */
.tc-popover-wrap{
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: 20px auto;
    cursor: pointer;
}
.utf-no,.utf-yes,.utf-notapplicable{
    display: inline-block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: 20px auto;
}
.tc-popover{
    position: absolute;
    left: 50%;
    bottom: 30px;
    margin: 0 0 0 -140px;
    width: 280px;
    display: none;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    border-radius:5px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    box-shadow: 0 0 8px rgba(0,0,0,.15)\9;
    z-index: 9;
}
.tc-popover::before {
    content: '';
    position: absolute;
    display: block;    
    width: 0px;        
    left: 50%;
    bottom: 5px;
    border: 10px solid transparent;
    border-bottom: 0;
    border-top: 10px solid #fff;
    transform: translate(-50%, calc(100% + 5px));
  }
.tc-popover h6{
    padding:10px;
    border-radius: 5px 5px 0 0;
    background:#1cb580;
    color: #fff;
    border-bottom: 1px solid #ddd;
}
.tc-popover ul{
    padding:20px 10px; 
    margin-left: 20px;
}
.tc-popover ul li{
    list-style: disc; 
    padding: 0;
    margin: 0 0 3px;
    font-size: 14px;
}
.tc-popover-wrap:hover .tc-popover{
    display: block;
}
@media screen and (max-width: 1199px) {
    .compare-table-outer .offset-lg-1{
        width: 100%!important;
        margin: 0!important;
    }
    .compare-table tr.gray-bg td{
        padding: 0!important;
    }
    .compare-table tr.gray-bg td a{
        padding: 10px;
        padding-right: 15px;
    }
    .compare-table th {
        padding: 10px;
    }
    .compare-table td{
        padding: 10px!important;
        min-width: 120px;
        max-width: 120px;
    }
    .compare-table td:first-child {
        min-width: 140px;
        max-width: 140px;
    }
    .compare-table tr:last-child td .button{
        width: 100%;
        min-width: 100%;
    }
}
@media screen and (max-width: 991px) {
    .compare-table{
        margin-bottom: 0;
    }
    .compare-table th {
        padding: 0 10px 10px 10px;
    }
    .compare-table tr td .m-box{
        text-align: center;
    }
    .compare-table tr td .m-box strong {
        font-size: 18px;
    }
}
@media screen and (max-width: 767px) {
    .compare-table-outer .offset-lg-1{
        margin: 0 12px!important;
        padding: 0 !important;
        width: calc(100% - 24px);
        max-width: calc(100% - 24px);
        overflow: auto;
    }
    .compare-table tr td:first-child,.compare-table th:first-child{
        position: sticky;
        left: -1px;
        z-index: 9;
    }
    .compare-table tr:last-child td:first-child,.compare-table tr:first-child th:first-child{
        background: #fff;
    }
    .compare-table td {
        font-size: 13px;
        min-width: 160px;
        max-width: 160px;
    }
}