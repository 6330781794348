.about-benifits{
    background: #f4f5f7;
}
.about-benifits-list{
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0 0 0;
}
.about-benifits-list li{
    width: 50%;
    padding: 0 0 20px;
    display: flex;
}
.about-benifits-list li .img{
    width: 25px;
    margin-right: 10px;
}
.about-benifits-list li .img img{
    max-width: 22px;
}
.about-benifits-list li .about-benifits-text{
    flex: 1;
}
.about-benifits-list li .about-benifits-text h4{
    font-size: 18px;
}
@media screen and (max-width: 991px) {
    .about-benifits-list {
        padding: 30px 0 0 0;
    }
    .about-benifits-list li{
        padding: 10px;
    }
}
@media screen and (max-width: 767px) {
    h2{
        font-size: 25px;
    }
    h3{
        font-size: 20px;
    }
    .about-benifits-list {
        padding: 0 0 30px 0;
        text-align: left;
    }
    .about-benifits-list li{
        width: 100%;
        padding: 10px 0;
    }
}
