.rank-sec h3{
    font-size: 36px;
    margin-bottom: 25px;
}
.rank-sec h4{
    font-size: 24px;
    margin-bottom: 25px;
}
.rank-sec p{
    margin-bottom: 25px;
}
@media screen and (max-width: 767px) {
    .rank-sec{
        text-align: left;
    }
    .rank-sec h3{
        font-size: 22px;
        margin-bottom: 10px;
    }
    .rank-sec h4 {
        font-size: 18px;
        margin-bottom: 10px;
    }
}