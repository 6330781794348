.company-feature h3{
    font-size: 36px;
    margin-bottom: 20px;
    font-weight: 600;
}
.company-feature h4{
    font-size: 24px;
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: 600;
}
.company-feature h4:first-child{
    margin-top: 0;
}
.company-feature p{
    margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
    .company-feature h4 {
        font-size: 20px;
        margin-top: 30px;
        margin-bottom: 10px;
    }
}