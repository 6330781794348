.banner{
    background: #e9ecef;
}
.banner-inner{
    min-height: 360px;
    align-items: center;
}
.banner-left{
    padding-top: 30px;
    padding-bottom: 80px;
}
.banner-left h1{
    font-size: 36px;
    color: #0385c7;
    font-weight: 700;
    margin: 0 0 25px 0;
}
.banner-left h1 span{
    color: #0385c7;
    text-transform: capitalize;
}
.banner-left p{
    font-size: 21px;
    line-height: 1.2;
    color: #707070;
}
.banner-right{
    min-height: 40px;
}
.banner-right img{
    max-height: 361px;
}
.lazy-banner-img{
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 385px;
    margin: 0 auto;
}
@media screen and (min-width: 1200px) {
    .lazy-banner-img{
        min-height: 361px;
    }
}
@media screen and (max-width: 1199px) {
    .lazy-banner-img{
        min-height: 260px;
    }
}
@media screen and (max-width: 767px) {
    .banner-inner{
        min-height: 100px;
    }
    /* 30/11/2022 */
    .banner-inner .lazy-banner-img{
        min-height: 200px;
    }
    .banner-inner .lazy-banner-img span{
        display: flex!important;
        position: relative;
        height: 200px;
        overflow: hidden;
    }
    .banner-inner .lazy-banner-img span img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
    }
    /*  */
    .banner-left{
        text-align: center;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .banner-left h1 {
        font-size: 30px;
        margin: 0 0 15px 0;
    }
    .banner-right{
        text-align: center;
    }
}