.blog-detail-list h2,.blog-detail-list h3,.blog-detail-list h4,.blog-detail-list p{
    margin-bottom: 20px;
}
.blog-detail-list .img{
    margin-bottom: 20px;
}
.blog-detail-list .img img{
    width: 100%;
}
.blog-detail-list h3{
    font-size: 24px;
}

.blog-detail-list .blog-d-img{
    background-repeat: no-repeat;
    background-size: cover;
}
ul.tcc-list-style li{
    margin-bottom: 15px;
    position: relative;
    padding: 0 0 0 15px;
}
ul.tcc-list-style li::before{
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #707070;
    left: 0;
    top: 7px;
    content: '';
}
@media screen and (min-width: 1200px) {
    .blog-detail-list .blog-d-img{
        min-height: 500px;
    }
    .blog-detail-list .blog-d-img .lazy-load-image-background{
        width: 100%;
    }
}
@media screen and (max-width: 1199px) {
    .blog-detail-list .blog-d-img{
        min-height: 220px;
    }
}