.recent-comparison{
    padding: 35px 0 50px;
    background: #212121;
}
.recent-comparison .heading1{
    color: #fff;
}
.recent-comparison .sub-heading-1{
    text-align: center;
    color: #ededed;
    font-size: 16px;
    line-height: 1.2;
    margin: 0 0 25px;
}
.recent-comparison .col-lg-2 {
    flex: 0 0 auto;
    width: 20%;
}
.recent-box{
    background: #ffffff;
    border-radius: 8px;
    padding: 20px;
    margin: 15px 0;
    height: calc(100% - 30px);
}
.recent-box strong{
    display: block;
    font-size: 25px;
    padding: 5px 0 0 0;
    color: #1cb580;
}
.recent-box .signin{
    display: inline-block;
    font-size: 25px;
    padding: 5px 0 0 0;
    margin: 0;
    background: none;
    border: none;
    color: #1cb580;
    font-weight: bold;
    text-decoration: underline;
}
.recent-box .signin:hover{
    text-decoration: none;
}
.recent-box span{
    clear: both;
    display: block;
    padding-bottom: 20px;
    min-height: 42px;
}
@media screen and (max-width: 1199px) {
    .recent-box{
        padding: 20px 10px;
    }
    .recent-box .button{
        min-width: 100%;
    }
}
@media screen and (max-width: 767px) {
    .recent-comparison .col-lg-2 {
        width: 50%;
    }
    .recent-box{
        padding: 20px;
        text-align: center;
        max-width: 300px;
        margin: 0 auto 20px auto;
        height: calc(100% - 20px);
    }
}