/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Noto+Sans+Display:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/slick-carousel/slick/slick.css"; 
@import "../node_modules/slick-carousel/slick/slick-theme.css";
body{
    /* font-family: 'Lato', sans-serif; */
    font-family: 'Inter', sans-serif;
    color:#707070;
    font-size: 14px;
    margin: 0;
    padding: 0;
    font-weight: 400;
}
a{
    text-decoration: none;
    transition: all .3s ease-in;
    color: #0385c7;
}
ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
img {
    max-width: 100%;
    vertical-align: middle;
    height: auto;
}
h1{
    font-size: 2.5rem;
}
h2{
    font-size: 2rem;
}
h3{
    font-size: 1.5rem;
}
h4{
    font-size: 1.25rem;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
    font-weight: 700;
    color: #333;
}
h6 {
    font-size: 20px;
    color: #0385c7;
    margin-bottom: 10px;
}
.blue-color{
    color: #0385c7!important;
}
p{
    line-height: 1.5;
    margin-bottom: 10px;
    font-size: 14px;
}
p:last-child{
    margin-bottom: 0px;
}
.text-left{
    text-align: left!important;
}
.text-center{
    text-align: center!important;
}
.text-right{
    text-align: right!important;
}
figure{
    margin: 0;
}
.container{
    max-width: 1230px;
}
.pb-30{
    padding-bottom: 30px!important;
}
.button{
    font-size: 14px;
    color: #fff;
    background: #0385c7;
    padding: 9px 20px;
    min-width: 130px;
    border-radius: 25px;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    transition: all .3s ease-in;
    border: 1px solid #0385c7;
}
.button:hover{
    background: none;
    color:#036fa5;
    text-decoration: none;
}
.button.green{
    background: #1cb580;
    border: 1px solid #1cb580;
}
.button.green:hover{
    background: none;
    color:#1cb580;
    text-decoration: none;
}
.button.cancel{
    background: none;
    border: 1px solid #ced4da;
    color: #999999;
}
.button.cancel:hover{
    border: 1px solid #000;
    color:#000;
    text-decoration: none;
}
.button.disabled{
    pointer-events: none;
    background: #999999;
    border: none;
}
.section{
    padding: 70px 0;
}
main{
    min-height: 800px;
}
.loader{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.85);
    background-position: center center;
    background-repeat: no-repeat;
}
.head p{
    font-size: 18px;
    color: #707070;
    text-align: center;
    margin-bottom: 20px;
}
.heading1{
    color: #333;
    font-size: 36px;
    line-height: 1.2;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 700;
}
.heading2{
    color: #707070;
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 20px;
    text-align: center;
}
.head.text-left .heading1,.head.text-left .heading1,.head.text-left p{
    text-align: left!important;
}   
.form-control{
    height: 42px;
    line-height: 42px;
    display: block;
    font-size: 14px;
    color: #707070;
}
.form-control:focus{
    box-shadow: none;
    border-color: #ced4da;
}
textarea.form-control{
    height: 150px;
    resize: none;
}
label {
    display: block;
    font-size: 12px;
    color: #666;
    margin-bottom: 5px;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #ccc;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #ccc;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #ccc;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #ccc;
  }
.input-outer{
    position: relative;
}
.input-outer .form-control{
    padding: 10px 100px 10px 10px;
    border-color: #bbdef0;
}
.input-outer .submit{
    position: absolute;
    top: 0;
    right: 0;
    background: #1cb580;
    border: none;
    width: 90px;
    padding: 2px 5px;
    color: #fff;
    border-radius: 0px 5px 5px 0;
    transition: background .5s ease-in;
}
.input-outer .submit:hover{
    background: #239c72;
}
.input-outer .info{
    padding: 30px 0 0;
    color: #6f7a8b;
    text-align: center;
}
.green-link{
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    box-shadow: none;
    color: #1cb580;
}
.green-link:hover{
    text-decoration: underline;
    color: #1cb580;
}
.green-link.disabled{
    pointer-events: none;
    opacity: .5;
}
.blue-link{
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    box-shadow: none;
    color: #0385c7;
}
.blue-link:hover{
    text-decoration: underline;
    color: #0385c7;
}
.blue-link.disabled{
    pointer-events: none;
    opacity: .5;
}
.success-msg{
    color: #1cb580;
}
.error-star{
    color: red!important;
    padding: 0!important;
    margin: 0 2px 0 0!important;
}
.error-msg{
    color: red!important;
    font-size: 12px;
    clear: both;
    display: block;
}
.error{
    position: absolute;
    color: red!important;
    margin: 0!important;
    font-size: 12px;
    clear: both;
    display: block;
}
.react-tabs{
    background: #fff;
    border: 1px solid #edeeef;
    border-radius: 8px;
    overflow: hidden;
}
.react-tabs .react-tabs__tab-list{
    display: flex;
    border-bottom: 1px solid #edeeef;
    margin: 0;
}
.react-tabs .react-tabs__tab-list li{
    padding: 15px 5px;
    flex: 1;
    text-align: center;
    color: #102131;
    bottom: 0;
    border: none;
    border-radius: 0;
}
.react-tabs .react-tabs__tab-list li.react-tabs__tab--selected{
    background: #cde7f4;
}
.react-tabs .react-tabs__tab-panel {
    padding: 20px 50px 25px;
}
.react-tabs .react-tabs__tab-panel h4{
    font-size: 30px;
    margin-bottom: 20px;
    color: #333;
}
/* Slick Slider */
.slick-dots li button:before {
    font-size: 17px;
}
/**/
.right-side-bar{
    padding: 20px;
    background: #f4f5f7;
}
.right-side-bar > div{
    margin-bottom: 20px;
}
.right-side-bar > div:last-child{
    margin-bottom: 0;
}
/*Common Content*/
.common-content h3{
    font-size: 24px;
    margin-bottom: 25px;
}
.common-content p{
    margin-bottom: 25px;
}
.common-content ul{
    list-style: inherit;
    padding: 0 0 0 25px;
    margin: 0 0 20px 0;
}
@media screen and (max-width: 991px) {
    .react-tabs .react-tabs__tab-panel {
        padding: 20px;
    }
}
@media screen and (max-width: 767px) {
    .section{
        padding: 30px 0;
    }
    main{
        min-height: 400px;
    }
    .heading1{
        font-size: 22px;
        margin-bottom: 10px!important;
    }
    .heading2{
        font-size: 18px;
        margin-bottom: 10px!important;
    }
    .heading1.mb-0,.heading2.mb-0 {
        margin-bottom: 0!important;
    }
    .head p {
        font-size: 14px;
    }
    .head.text-left .heading1,.head.text-left .heading1,.head.text-left p{
        margin-bottom: 0;
    } 
    .react-responsive-modal-modal .form-control {
        height: 32px;
        line-height: 32px;
    }
    .react-responsive-modal-modal .button {
        min-width: 110px;
        padding: 5px 10px;
        margin-top: 10px;
    }
    .react-responsive-modal-modal .form-group.mb-3{
        margin-bottom: 4px!important;
    }
    .react-tabs .react-tabs__tab-list li{
        padding: 10px 5px;
    }
    .react-tabs .react-tabs__tab-panel {
        padding: 15px;
    }
    .react-tabs .react-tabs__tab-panel h4 {
        font-size: 22px;
        margin-bottom: 10px;
    }
    .common-content h3{
        font-size: 22px;
        margin-bottom: 10px;
    }
    .common-content h4 {
        font-size: 18px;
        margin-bottom: 10px;
    }
}