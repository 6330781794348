.city-outer{
    background: #f4f5f7;
}
.city-outer.white-bg{
    background: #fff;
}
.city-box{
    background: #0385c7;
    border-radius: 20px;
    padding: 40px 20px;
    margin: -60px auto 0;
    position: relative;
    border: 1px solid #0385c7;
    display: flex;
    align-items: center;
}
.city-box .input-outer{
    margin: 0 20px;
    flex: 1;
}
.city-box .input-outer .form-control{
    padding-left: 175px;
    padding-right: 62px;
    /* border-color: #b3daee; */
    border: none;
}
.city-box .input-outer .submit{
    background: #49c499;
    padding: 2px 5px!important;
    width: 55px;
    font-size: 22px;
    font-weight: 100;
    line-height: 1;
    right: -2px;
}
.city-box .input-outer .submit:hover{
    background: #239c72;
}
.city-box .input-outer .location-icon{
    position: absolute;
    top: 12px;
    left: 150px;
    color: #e76f6f;
    font-size: 17px;
}
.pincode{
    position: absolute;
    width: 140px;
    height: calc(100% - 14px);
    top: 6px;
    bottom: 0;
    line-height: 32px;
    padding: 0 15px;
    letter-spacing: 10px;
    color: #000;
    font-weight: bold;
    border-right: 1px solid #ccc;
    cursor: default;
}
.city-location{
    font-size: 15px;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #fff;
    text-transform: capitalize;
    letter-spacing: 1px;
    margin: 0;
    min-width: 100px;
    text-align: center;
}
.already-login{
    font-size: 12px;
    color: #fff;
}
.already-login .login{
    color: #fff;
    font-weight: bold;
    padding: 0;
    margin: 0 0 0 3px;
    border: none;
    background: none;
} 
.already-login .login:hover{
    text-decoration: underline;
}
@media screen and (max-width: 991px) {
    .city-box .input-outer .location-icon {
        left: 127px;
    }
    .pincode {
        width: 120px;
        letter-spacing: 4px;
    }
    .city-box .input-outer .form-control {
        padding-left: 147px;
    }    
}

@media screen and (max-width: 767px) {
    .city-box .input-outer .location-icon {
        left: 64px;
        font-size: 13px;
        top: 14px;
    }
    .pincode {
        width: 60px;
        letter-spacing: 1px;
        padding: 0 10px;
        font-size: 11px;
    }
    .city-box .input-outer .form-control {
        padding-left: 80px;
        font-size: 11px;
    }   
    .city-box{
        flex-direction: column;
        border-radius: 10px;
        margin: -40px 0 0 0;
        padding: 15px;
    }
    .city-box .input-outer{
        width: 100%;
        margin: 5px 0;
    }
    .already-login {
        right: 14px;
        bottom: 8px;
    }
}