.mob-menu-open{
  overflow: hidden!important;
}
.header-outer{
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 99;
}
.header{
  background: #fff;
  width: 100%;
  box-shadow: 0 0 10px #ccc;
  position: relative;
  z-index: 99;
}
.header-inner{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-inner .logo img{
  max-height: 32px;
}
nav{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.menu{
  display: flex;
  align-items: center;
}
.menu li{
  position: relative;
  margin: 0 15px;
}
.menu li a{
  display: block;
  color: #102131;
  font-size: 14px;
  font-weight: 500;
  padding: 22px 10px 18px 0;
  border-bottom: 2px solid transparent;
  position: relative;
}
.menu li a::after {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  right: 0;
  top: 50%;
  margin-top: -1px;
  border-right: 2px solid;
  border-bottom: 2px solid;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: transform .3s;
}
.menu li:last-child a {
  padding-right: 0;
}
.menu li:last-child a::after {
  display: none;
}
.menu li:hover > a{
  text-decoration: none;
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}
.menu li a.active{
  text-decoration: none;
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}
.sub-menu{
  min-width: 235px;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  left: -2px;
  color: rgba(0,0,0,.65);
  font-size: 14px;
  background: #fff;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  border-radius: 2px;
  padding: 5px 0 0 0;
  margin: 0;
  display: none;
}
.sub-menu li{
  margin: 0;
}
.sub-menu li a{
  font-size: 14px;
  line-height: 30px;
  padding: 0 16px;
  margin: 0 0 10px 0;
  white-space: nowrap;
  border: none!important;
  display: block;
  color: #102131;
}
.sub-menu li a:hover{
  color: #1890ff;
}
.sub-menu li a::after {
  display: none;
}
.menu li:hover .sub-menu{
  display: block;
}
.header-login{
  display: flex;
  align-items: center;
  margin-left: 130px;
}
.header-login li{
  margin: 0 15px;
}
.header-login li:last-child{ 
  margin-right: 0;
}
.header-login li a{
  color: #102131;
  font-size: 14px;
  font-weight: 500;
}
.header-login li .sign-in{
  color: #fff;
  background: #1cb580;
  padding: 6px 20px;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid #1cb580;
  white-space: nowrap;
}
.header-login li .sign-in:hover{
  background:none;
  color: #1cb580;
}
.header-login li a:hover{
  text-decoration: none;
  color: #1890ff;
}

/**/
.sub-menu-arrow{
    width: 34px;
    height: 37px;
    top: 0;
    right: 0;
    position: absolute;
    z-index: 99;
    display: none;
}
.sub-menu-arrow::after {
  content: "";
    position: absolute;
    width: 9px;
    height: 9px;
    left: 13px;
    top: 50%;
    margin-top: -6px;
    border-right: 2px solid;
    border-bottom: 2px solid;
    transform: rotate(45deg);
    transition: transform .3s;
}
.mob-nav-overlay{
  display: none;
  cursor: pointer;
  width: calc(100% - 280px);
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  background: rgba(0,0,0,.5);
  visibility: hidden;
  opacity: 0;
}
.menu-icon {
  display: none;
  position: absolute;
  right: 10px;
  font-weight: bold;
  line-height: 1;
  top: 8px; 
} 
.menu-icon .bar1, .menu-icon .bar2, .menu-icon .bar3 {
width: 28px;
height: 3px;
background-color: #049fe1;
margin: 6px 0;
transition: 0.4s;
display: block; 
}
.menu-icon.active .bar1 {
-webkit-transform: rotate(-45deg) translate(-5px, 5px);
transform: rotate(-45deg) translate(-5px, 5px); 
}
.menu-icon.active .bar2 {
opacity: 0; 
}
.menu-icon.active .bar3 {
-webkit-transform: rotate(45deg) translate(-8px, -8px);
transform: rotate(45deg) translate(-8px, -8px); 
}
.header-right{
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
}
.header-right .user-profile{
  margin: 0 0 0 140px;
  position: relative;
  padding-right: 12px;
  line-height: 1;
  cursor: pointer;
}
.header-right .user-profile::after {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  right: 0;
  top: 50%;
  margin-top: -3px;
  border-right: 2px solid;
  border-bottom: 2px solid;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: transform .3s;
}
.header-right .user-profile .user-img{
  width: 31px;
  height: 31px;
  margin: 0 10px 0 0;
  border: 0px;
}
.header-right .user-profile .user-img span{
  font-size: 18px;
  margin-top: -2px;
}
.header-right .user-profile p{
  font-size: 14px;
  overflow: hidden;
  margin: 0;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95px;
}
.header-right .user-profile .sub-menu{
  min-width: 144px;
  right: -2px;
  left: auto;
  position: absolute;
  border-top: 2px solid #1890ff;
}
.header-right .user-profile .sub-menu .button{
  width: calc(100% - 20px);
    margin: 0 10px 10px;
}
.header-right .user-profile:hover .sub-menu{
  display: block;
}
/*React popup*/
.react-responsive-modal-modal{
  padding: 30px;
  margin: 15px;
  width: calc(100% - 30px);
  max-width: 900px;
  border-radius: 8px;
}
#signin-popup,#signup-popup{
  padding: 70px;
  text-align: center;
}
.medel-head figure{
  margin-bottom: 10px;
}
.medel-head h3{
  color: #333;
}
#signin-popup p small a,#signup-popup p small a{
 margin: 0 5px; 
 display: inline-block;
}
.login-list{
  display: flex;
  justify-content: center;
  align-items: center;
    margin: 30px 0 20px;
}
.login-list li{
  margin: 7px;
  position: relative;
  transition: all .5s ease;
  transform: translateY(0);
  overflow: hidden;
}
.login-list li:first-child:hover{
  transform: translateY(0);
}
.login-list li:hover{
  transform: translateY(-3px);
}
.login-list li strong{
  font-size: 17px;
}
.login-list li > button,
.login-list li > svg,
.login-list li > span,
.login-list li > span > button{
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0!important;
  padding: 0!important;
  margin: 0!important;
  width: 100%!important;
  height: 100%;
  z-index: 9;
  cursor: pointer;
}
.login-list li a{
  background: #3c5a99;
  color: #fff;
  font-size: 17px;
  border-radius: 25px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-list li a.facebook{
  background: #3c5a99;
}
.login-list li a.gmail{
  background: #eb2531;
}
.login-list li a.twitter{
  background: #0a9ef7;
}
.login-list li a.email{
  background: #3c3c3b;
}
.login-list li a.apple{
  background: #424f57;
}
.login-list li a.microsoft{
  background: #7aa50f;
}
.already-signup span{
  margin-top: 20px;
  display: block;
  font-size: 20px;
}
.login-form{
  text-align: left;
  margin: 20px 0 30px;
}
.login-form .btn-outer{
  text-align: center;
}
.login-form .btn-outer .button{
  margin: 5px;
}
.or-text{
  position: relative;
  z-index: 1;
}
.or-text::before{
  content: '';
  position: absolute;
  background: #bbb;
  left: 0;
  top: 50%;
  z-index: -1;
  width: 100%;
  height: 1px;
}
.or-text strong{
  background: #fff;
  padding: 0 15px;
  display: inline-block;
  font-weight: 500;
  font-size: 1.5rem;
  color: #333;
}
/*Forgot Password*/
.forgot-password{
  text-align: left;
  margin: 20px 0 30px;
}
.get-otp-box,.reset-otp-box{
  position: relative;
}
.get-otp-box .form-control,.reset-otp-box .form-control{
  padding-right: 95px;
}
.get-otp,.reset-otp{
  position: absolute;
  bottom: 11px;
  z-index: 9;
  right: 10px;
}
@media screen and (max-width: 1199px) {
  .header-login {
    margin-left: 40px;
  }
  .header-login li {
    margin: 0 10px;
  }
  .header-right .user-profile{
    margin: 0 0 0 50px;
  }
  .menu li {
    margin: 0 10px;
  }
}
@media screen and (max-width: 991px) {
  .header{
    padding: 10px 0;
  }
  .header-inner .logo img {
    max-height: 28px;
  }
  .menu-icon,.sub-menu-arrow {
    display: block;
  }
  nav{
    background: #fff;
    position: fixed;
    top: 0;
    height: 100%;
    width: 280px;
    right: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    padding: 50px 0;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    transform: translateX(110%);
    transition: all .4s;
    z-index: 99;
  }
  nav::before{
    content: '';
    position: absolute;
    top: 0; 
    left: 0;
    height: 50px;
    width: 100%;
    border-bottom: 2px solid #edeeef;
  }
  .mob-menu-open nav{
    transform: none;
  }
  .mob-menu-open .mob-nav-overlay{
    display: block;
    visibility: visible;
    opacity: 1;
  }
  .menu{
    width: 100%;
    align-items: self-start;
    width: 100%;
    flex-direction: column;
    height: 100%;
    overflow: auto;
  }
  .menu li {
    width: 100%;
    margin: 0;
  }
  .menu li:hover > a {
    color: inherit;
  }
  .menu li a {
    margin: 0;
    padding: 8px 40px 8px 20px!important;
    position: relative;
    border: none!important;
  }
  .menu li > a:hover {
    text-decoration: none;
    color: #1890ff;
    border-bottom: 2px solid #1890ff;
  }
  .menu li a::after {
    display: none;
  }
  .menu li:hover .sub-menu {
    display: none;
  }
  .sub-menu{
    position: static;
    display: none;
    visibility: visible;
    opacity: 1;
    box-shadow: none;
    padding: 0;
  }
  .sub-menu li a {
    width: 100%;
    padding: 8px 10px 8px 40px!important;
    margin: 0;
    white-space: inherit;
    line-height: 1.3;
  }
  .sub-menu-arrow.active::after{
      content: "";
      position: absolute;
      width: 9px;
      height: 9px;
      left: 13px;
      top: 50%;
      margin-top: -2px;
      border-right: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(225deg);
      transition: transform .3s;
  }
  .sub-menu-arrow.active + .sub-menu{
    display: block!important;
  }
  .header-login{
    margin: 0;
    width: 100%;
    background: #0385c7;
    position: absolute;
    bottom: 0;
  }
  .header-login li{
    margin: 0;
    flex: 1;
    text-align: center;
  }
  .header-login li a,.header-login li .sign-in{
    margin: 0!important;
    padding: 8px 5px;
    color: #fff;
    display: block;
    width: 100%;
    border-radius: 0px;
    text-transform: uppercase;
    font-size: 18px;
  }
  .header-right {
    position: absolute;
    top: 9px;
    right: 55px;
    max-width: 150px;
    z-index: 1;
  }
  .header-right .user-profile{
    margin: 0;
  }
  /* .header-right .user-profile:hover .sub-menu{
    display: none;
  } */
  .header-right .user-profile .sub-menu {
    top: 33px;
    right: -5px;
  }
  .header-right .user-profile .sub-menu a{
    padding-left: 10px!important;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  #signin-popup, #signup-popup {
    padding: 35px 20px 20px;
  }
}
  @media screen and (max-width: 575px) {
    /* .header .container{
      padding-left: 10px;
      padding-right: 10px;
    } */
    .header-right {
      top: 12px;
    }
    .header-right .user-profile .user-img {
      width: 25px;
      height: 25px;
      margin: 0 5px 0 0;
      border: 0px;
    }
    .header-right .user-profile .user-img span {
      font-size: 14px;
      margin-top: -1px;
    }
    .header-right .user-profile p {
      font-size: 13px;
    }
    #signin-popup .medel-head figure, #signup-popup .medel-head figure{
      margin: -15px 0 5px;
    }
    #signin-popup .medel-head figure img, #signup-popup .medel-head figure img{
      max-height: 30px;
    }
    .login-list {
      margin: 10px 0;
    }
    .login-list li {
      margin: 4px;
    }
    .login-list li a {
      font-size: 15px;
      width: 30px;
      height: 30px;
  }
    .already-signup span {
      margin-top: 0;
      font-size: 17px;
    }
    .or-text strong{
      font-size: 18px;
    }
    .login-form {
      margin: 10px 0 10px;
    }
  }