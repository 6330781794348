.tcc-feature-left img{
    margin-top: -35px;
}
.tcc-feature-list{
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0;
    align-items: center;
    height: 100%;
}
.tcc-feature-list li{
    width: 50%;
    padding: 20px;
    display: flex;
}
.tcc-feature-list li .img{
    width: 25px;
    margin-right: 10px;
}
.tcc-feature-list li .img img{
    max-width: 22px;
}
.tcc-feature-list li .tcc-feature-text{
    flex: 1;
}
.tcc-feature-list li .tcc-feature-text h4{
    font-size: 18px;
    color: #333;
}
@media screen and (max-width: 991px) {
    .tcc-feature-list {
        padding: 30px 0 0 0;
    }
    .tcc-feature-list li{
        padding: 10px;
    }
}
@media screen and (max-width: 767px) {
    .tcc-feature-left{
        text-align: center;
    }
    .tcc-feature-list {
        padding: 0 0 30px 0;
    }
    .tcc-feature-list li{
        width: 100%;
        padding: 10px 0;
    }
    .tcc-feature-left img {
        max-height: 250px;
    }
}
