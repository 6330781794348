.best-deals{
    background: #f4f5f7;
}
.best-deals .heading1{
    margin-bottom: 50px;
}
.deal-img{
    margin: 10px 0;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
}
@media screen and (min-width: 1200px) {
    .deal-img{
        min-height: 169px;
    }
}
.deal-img img{
    border-radius: 10px;
}
.best-deals .react-tabs {
    margin: 10px 0;
    /* min-height: calc(100% - 20px); */
    min-height: 353px;
}
.best-deals .box-switch .col-md-6:first-child{
    order: 1;
}
@media screen and (max-width: 1199px) {
    .best-deals .react-tabs{
        min-height: calc(100% - 20px);
    }
}
@media screen and (max-width: 767px) {
    .best-deals .box-switch .col-md-6:first-child{
        order: unset;
    }
}