@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Noto+Sans+Display:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);

/* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap'); */
body{
    /* font-family: 'Lato', sans-serif; */
    font-family: 'Inter', sans-serif;
    color:#707070;
    font-size: 14px;
    margin: 0;
    padding: 0;
    font-weight: 400;
}
a{
    text-decoration: none;
    transition: all .3s ease-in;
    color: #0385c7;
}
ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
img {
    max-width: 100%;
    vertical-align: middle;
    height: auto;
}
h1{
    font-size: 2.5rem;
}
h2{
    font-size: 2rem;
}
h3{
    font-size: 1.5rem;
}
h4{
    font-size: 1.25rem;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
    font-weight: 700;
    color: #333;
}
h6 {
    font-size: 20px;
    color: #0385c7;
    margin-bottom: 10px;
}
.blue-color{
    color: #0385c7!important;
}
p{
    line-height: 1.5;
    margin-bottom: 10px;
    font-size: 14px;
}
p:last-child{
    margin-bottom: 0px;
}
.text-left{
    text-align: left!important;
}
.text-center{
    text-align: center!important;
}
.text-right{
    text-align: right!important;
}
figure{
    margin: 0;
}
.container{
    max-width: 1230px;
}
.pb-30{
    padding-bottom: 30px!important;
}
.button{
    font-size: 14px;
    color: #fff;
    background: #0385c7;
    padding: 9px 20px;
    min-width: 130px;
    border-radius: 25px;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    transition: all .3s ease-in;
    border: 1px solid #0385c7;
}
.button:hover{
    background: none;
    color:#036fa5;
    text-decoration: none;
}
.button.green{
    background: #1cb580;
    border: 1px solid #1cb580;
}
.button.green:hover{
    background: none;
    color:#1cb580;
    text-decoration: none;
}
.button.cancel{
    background: none;
    border: 1px solid #ced4da;
    color: #999999;
}
.button.cancel:hover{
    border: 1px solid #000;
    color:#000;
    text-decoration: none;
}
.button.disabled{
    pointer-events: none;
    background: #999999;
    border: none;
}
.section{
    padding: 70px 0;
}
main{
    min-height: 800px;
}
.loader{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.85);
    background-position: center center;
    background-repeat: no-repeat;
}
.head p{
    font-size: 18px;
    color: #707070;
    text-align: center;
    margin-bottom: 20px;
}
.heading1{
    color: #333;
    font-size: 36px;
    line-height: 1.2;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 700;
}
.heading2{
    color: #707070;
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 20px;
    text-align: center;
}
.head.text-left .heading1,.head.text-left .heading1,.head.text-left p{
    text-align: left!important;
}   
.form-control{
    height: 42px;
    line-height: 42px;
    display: block;
    font-size: 14px;
    color: #707070;
}
.form-control:focus{
    box-shadow: none;
    border-color: #ced4da;
}
textarea.form-control{
    height: 150px;
    resize: none;
}
label {
    display: block;
    font-size: 12px;
    color: #666;
    margin-bottom: 5px;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #ccc;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #ccc;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #ccc;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #ccc;
  }
.input-outer{
    position: relative;
}
.input-outer .form-control{
    padding: 10px 100px 10px 10px;
    border-color: #bbdef0;
}
.input-outer .submit{
    position: absolute;
    top: 0;
    right: 0;
    background: #1cb580;
    border: none;
    width: 90px;
    padding: 2px 5px;
    color: #fff;
    border-radius: 0px 5px 5px 0;
    transition: background .5s ease-in;
}
.input-outer .submit:hover{
    background: #239c72;
}
.input-outer .info{
    padding: 30px 0 0;
    color: #6f7a8b;
    text-align: center;
}
.green-link{
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    box-shadow: none;
    color: #1cb580;
}
.green-link:hover{
    text-decoration: underline;
    color: #1cb580;
}
.green-link.disabled{
    pointer-events: none;
    opacity: .5;
}
.blue-link{
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    box-shadow: none;
    color: #0385c7;
}
.blue-link:hover{
    text-decoration: underline;
    color: #0385c7;
}
.blue-link.disabled{
    pointer-events: none;
    opacity: .5;
}
.success-msg{
    color: #1cb580;
}
.error-star{
    color: red!important;
    padding: 0!important;
    margin: 0 2px 0 0!important;
}
.error-msg{
    color: red!important;
    font-size: 12px;
    clear: both;
    display: block;
}
.error{
    position: absolute;
    color: red!important;
    margin: 0!important;
    font-size: 12px;
    clear: both;
    display: block;
}
.react-tabs{
    background: #fff;
    border: 1px solid #edeeef;
    border-radius: 8px;
    overflow: hidden;
}
.react-tabs .react-tabs__tab-list{
    display: flex;
    border-bottom: 1px solid #edeeef;
    margin: 0;
}
.react-tabs .react-tabs__tab-list li{
    padding: 15px 5px;
    flex: 1 1;
    text-align: center;
    color: #102131;
    bottom: 0;
    border: none;
    border-radius: 0;
}
.react-tabs .react-tabs__tab-list li.react-tabs__tab--selected{
    background: #cde7f4;
}
.react-tabs .react-tabs__tab-panel {
    padding: 20px 50px 25px;
}
.react-tabs .react-tabs__tab-panel h4{
    font-size: 30px;
    margin-bottom: 20px;
    color: #333;
}
/* Slick Slider */
.slick-dots li button:before {
    font-size: 17px;
}
/**/
.right-side-bar{
    padding: 20px;
    background: #f4f5f7;
}
.right-side-bar > div{
    margin-bottom: 20px;
}
.right-side-bar > div:last-child{
    margin-bottom: 0;
}
/*Common Content*/
.common-content h3{
    font-size: 24px;
    margin-bottom: 25px;
}
.common-content p{
    margin-bottom: 25px;
}
.common-content ul{
    list-style: inherit;
    padding: 0 0 0 25px;
    margin: 0 0 20px 0;
}
@media screen and (max-width: 991px) {
    .react-tabs .react-tabs__tab-panel {
        padding: 20px;
    }
}
@media screen and (max-width: 767px) {
    .section{
        padding: 30px 0;
    }
    main{
        min-height: 400px;
    }
    .heading1{
        font-size: 22px;
        margin-bottom: 10px!important;
    }
    .heading2{
        font-size: 18px;
        margin-bottom: 10px!important;
    }
    .heading1.mb-0,.heading2.mb-0 {
        margin-bottom: 0!important;
    }
    .head p {
        font-size: 14px;
    }
    .head.text-left .heading1,.head.text-left .heading1,.head.text-left p{
        margin-bottom: 0;
    } 
    .react-responsive-modal-modal .form-control {
        height: 32px;
        line-height: 32px;
    }
    .react-responsive-modal-modal .button {
        min-width: 110px;
        padding: 5px 10px;
        margin-top: 10px;
    }
    .react-responsive-modal-modal .form-group.mb-3{
        margin-bottom: 4px!important;
    }
    .react-tabs .react-tabs__tab-list li{
        padding: 10px 5px;
    }
    .react-tabs .react-tabs__tab-panel {
        padding: 15px;
    }
    .react-tabs .react-tabs__tab-panel h4 {
        font-size: 22px;
        margin-bottom: 10px;
    }
    .common-content h3{
        font-size: 22px;
        margin-bottom: 10px;
    }
    .common-content h4 {
        font-size: 18px;
        margin-bottom: 10px;
    }
}
#reset-password{
    max-width: 340px;
}
#reset-password .btn-outer .button {
    margin: 5px;
}
.mob-menu-open{
  overflow: hidden!important;
}
.header-outer{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 99;
}
.header{
  background: #fff;
  width: 100%;
  box-shadow: 0 0 10px #ccc;
  position: relative;
  z-index: 99;
}
.header-inner{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-inner .logo img{
  max-height: 32px;
}
nav{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.menu{
  display: flex;
  align-items: center;
}
.menu li{
  position: relative;
  margin: 0 15px;
}
.menu li a{
  display: block;
  color: #102131;
  font-size: 14px;
  font-weight: 500;
  padding: 22px 10px 18px 0;
  border-bottom: 2px solid transparent;
  position: relative;
}
.menu li a::after {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  right: 0;
  top: 50%;
  margin-top: -1px;
  border-right: 2px solid;
  border-bottom: 2px solid;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
}
.menu li:last-child a {
  padding-right: 0;
}
.menu li:last-child a::after {
  display: none;
}
.menu li:hover > a{
  text-decoration: none;
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}
.menu li a.active{
  text-decoration: none;
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}
.sub-menu{
  min-width: 235px;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  left: -2px;
  color: rgba(0,0,0,.65);
  font-size: 14px;
  background: #fff;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  border-radius: 2px;
  padding: 5px 0 0 0;
  margin: 0;
  display: none;
}
.sub-menu li{
  margin: 0;
}
.sub-menu li a{
  font-size: 14px;
  line-height: 30px;
  padding: 0 16px;
  margin: 0 0 10px 0;
  white-space: nowrap;
  border: none!important;
  display: block;
  color: #102131;
}
.sub-menu li a:hover{
  color: #1890ff;
}
.sub-menu li a::after {
  display: none;
}
.menu li:hover .sub-menu{
  display: block;
}
.header-login{
  display: flex;
  align-items: center;
  margin-left: 130px;
}
.header-login li{
  margin: 0 15px;
}
.header-login li:last-child{ 
  margin-right: 0;
}
.header-login li a{
  color: #102131;
  font-size: 14px;
  font-weight: 500;
}
.header-login li .sign-in{
  color: #fff;
  background: #1cb580;
  padding: 6px 20px;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid #1cb580;
  white-space: nowrap;
}
.header-login li .sign-in:hover{
  background:none;
  color: #1cb580;
}
.header-login li a:hover{
  text-decoration: none;
  color: #1890ff;
}

/**/
.sub-menu-arrow{
    width: 34px;
    height: 37px;
    top: 0;
    right: 0;
    position: absolute;
    z-index: 99;
    display: none;
}
.sub-menu-arrow::after {
  content: "";
    position: absolute;
    width: 9px;
    height: 9px;
    left: 13px;
    top: 50%;
    margin-top: -6px;
    border-right: 2px solid;
    border-bottom: 2px solid;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
}
.mob-nav-overlay{
  display: none;
  cursor: pointer;
  width: calc(100% - 280px);
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  background: rgba(0,0,0,.5);
  visibility: hidden;
  opacity: 0;
}
.menu-icon {
  display: none;
  position: absolute;
  right: 10px;
  font-weight: bold;
  line-height: 1;
  top: 8px; 
} 
.menu-icon .bar1, .menu-icon .bar2, .menu-icon .bar3 {
width: 28px;
height: 3px;
background-color: #049fe1;
margin: 6px 0;
transition: 0.4s;
display: block; 
}
.menu-icon.active .bar1 {
-webkit-transform: rotate(-45deg) translate(-5px, 5px);
transform: rotate(-45deg) translate(-5px, 5px); 
}
.menu-icon.active .bar2 {
opacity: 0; 
}
.menu-icon.active .bar3 {
-webkit-transform: rotate(45deg) translate(-8px, -8px);
transform: rotate(45deg) translate(-8px, -8px); 
}
.header-right{
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
}
.header-right .user-profile{
  margin: 0 0 0 140px;
  position: relative;
  padding-right: 12px;
  line-height: 1;
  cursor: pointer;
}
.header-right .user-profile::after {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  right: 0;
  top: 50%;
  margin-top: -3px;
  border-right: 2px solid;
  border-bottom: 2px solid;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
}
.header-right .user-profile .user-img{
  width: 31px;
  height: 31px;
  margin: 0 10px 0 0;
  border: 0px;
}
.header-right .user-profile .user-img span{
  font-size: 18px;
  margin-top: -2px;
}
.header-right .user-profile p{
  font-size: 14px;
  overflow: hidden;
  margin: 0;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 95px;
}
.header-right .user-profile .sub-menu{
  min-width: 144px;
  right: -2px;
  left: auto;
  position: absolute;
  border-top: 2px solid #1890ff;
}
.header-right .user-profile .sub-menu .button{
  width: calc(100% - 20px);
    margin: 0 10px 10px;
}
.header-right .user-profile:hover .sub-menu{
  display: block;
}
/*React popup*/
.react-responsive-modal-modal{
  padding: 30px;
  margin: 15px;
  width: calc(100% - 30px);
  max-width: 900px;
  border-radius: 8px;
}
#signin-popup,#signup-popup{
  padding: 70px;
  text-align: center;
}
.medel-head figure{
  margin-bottom: 10px;
}
.medel-head h3{
  color: #333;
}
#signin-popup p small a,#signup-popup p small a{
 margin: 0 5px; 
 display: inline-block;
}
.login-list{
  display: flex;
  justify-content: center;
  align-items: center;
    margin: 30px 0 20px;
}
.login-list li{
  margin: 7px;
  position: relative;
  transition: all .5s ease;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  overflow: hidden;
}
.login-list li:first-child:hover{
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.login-list li:hover{
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}
.login-list li strong{
  font-size: 17px;
}
.login-list li > button,
.login-list li > svg,
.login-list li > span,
.login-list li > span > button{
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0!important;
  padding: 0!important;
  margin: 0!important;
  width: 100%!important;
  height: 100%;
  z-index: 9;
  cursor: pointer;
}
.login-list li a{
  background: #3c5a99;
  color: #fff;
  font-size: 17px;
  border-radius: 25px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-list li a.facebook{
  background: #3c5a99;
}
.login-list li a.gmail{
  background: #eb2531;
}
.login-list li a.twitter{
  background: #0a9ef7;
}
.login-list li a.email{
  background: #3c3c3b;
}
.login-list li a.apple{
  background: #424f57;
}
.login-list li a.microsoft{
  background: #7aa50f;
}
.already-signup span{
  margin-top: 20px;
  display: block;
  font-size: 20px;
}
.login-form{
  text-align: left;
  margin: 20px 0 30px;
}
.login-form .btn-outer{
  text-align: center;
}
.login-form .btn-outer .button{
  margin: 5px;
}
.or-text{
  position: relative;
  z-index: 1;
}
.or-text::before{
  content: '';
  position: absolute;
  background: #bbb;
  left: 0;
  top: 50%;
  z-index: -1;
  width: 100%;
  height: 1px;
}
.or-text strong{
  background: #fff;
  padding: 0 15px;
  display: inline-block;
  font-weight: 500;
  font-size: 1.5rem;
  color: #333;
}
/*Forgot Password*/
.forgot-password{
  text-align: left;
  margin: 20px 0 30px;
}
.get-otp-box,.reset-otp-box{
  position: relative;
}
.get-otp-box .form-control,.reset-otp-box .form-control{
  padding-right: 95px;
}
.get-otp,.reset-otp{
  position: absolute;
  bottom: 11px;
  z-index: 9;
  right: 10px;
}
@media screen and (max-width: 1199px) {
  .header-login {
    margin-left: 40px;
  }
  .header-login li {
    margin: 0 10px;
  }
  .header-right .user-profile{
    margin: 0 0 0 50px;
  }
  .menu li {
    margin: 0 10px;
  }
}
@media screen and (max-width: 991px) {
  .header{
    padding: 10px 0;
  }
  .header-inner .logo img {
    max-height: 28px;
  }
  .menu-icon,.sub-menu-arrow {
    display: block;
  }
  nav{
    background: #fff;
    position: fixed;
    top: 0;
    height: 100%;
    width: 280px;
    right: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    padding: 50px 0;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    -webkit-transform: translateX(110%);
            transform: translateX(110%);
    transition: all .4s;
    z-index: 99;
  }
  nav::before{
    content: '';
    position: absolute;
    top: 0; 
    left: 0;
    height: 50px;
    width: 100%;
    border-bottom: 2px solid #edeeef;
  }
  .mob-menu-open nav{
    -webkit-transform: none;
            transform: none;
  }
  .mob-menu-open .mob-nav-overlay{
    display: block;
    visibility: visible;
    opacity: 1;
  }
  .menu{
    width: 100%;
    align-items: self-start;
    width: 100%;
    flex-direction: column;
    height: 100%;
    overflow: auto;
  }
  .menu li {
    width: 100%;
    margin: 0;
  }
  .menu li:hover > a {
    color: inherit;
  }
  .menu li a {
    margin: 0;
    padding: 8px 40px 8px 20px!important;
    position: relative;
    border: none!important;
  }
  .menu li > a:hover {
    text-decoration: none;
    color: #1890ff;
    border-bottom: 2px solid #1890ff;
  }
  .menu li a::after {
    display: none;
  }
  .menu li:hover .sub-menu {
    display: none;
  }
  .sub-menu{
    position: static;
    display: none;
    visibility: visible;
    opacity: 1;
    box-shadow: none;
    padding: 0;
  }
  .sub-menu li a {
    width: 100%;
    padding: 8px 10px 8px 40px!important;
    margin: 0;
    white-space: inherit;
    line-height: 1.3;
  }
  .sub-menu-arrow.active::after{
      content: "";
      position: absolute;
      width: 9px;
      height: 9px;
      left: 13px;
      top: 50%;
      margin-top: -2px;
      border-right: 2px solid;
      border-bottom: 2px solid;
      -webkit-transform: rotate(225deg);
              transform: rotate(225deg);
      transition: -webkit-transform .3s;
      transition: transform .3s;
      transition: transform .3s, -webkit-transform .3s;
  }
  .sub-menu-arrow.active + .sub-menu{
    display: block!important;
  }
  .header-login{
    margin: 0;
    width: 100%;
    background: #0385c7;
    position: absolute;
    bottom: 0;
  }
  .header-login li{
    margin: 0;
    flex: 1 1;
    text-align: center;
  }
  .header-login li a,.header-login li .sign-in{
    margin: 0!important;
    padding: 8px 5px;
    color: #fff;
    display: block;
    width: 100%;
    border-radius: 0px;
    text-transform: uppercase;
    font-size: 18px;
  }
  .header-right {
    position: absolute;
    top: 9px;
    right: 55px;
    max-width: 150px;
    z-index: 1;
  }
  .header-right .user-profile{
    margin: 0;
  }
  /* .header-right .user-profile:hover .sub-menu{
    display: none;
  } */
  .header-right .user-profile .sub-menu {
    top: 33px;
    right: -5px;
  }
  .header-right .user-profile .sub-menu a{
    padding-left: 10px!important;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  #signin-popup, #signup-popup {
    padding: 35px 20px 20px;
  }
}
  @media screen and (max-width: 575px) {
    /* .header .container{
      padding-left: 10px;
      padding-right: 10px;
    } */
    .header-right {
      top: 12px;
    }
    .header-right .user-profile .user-img {
      width: 25px;
      height: 25px;
      margin: 0 5px 0 0;
      border: 0px;
    }
    .header-right .user-profile .user-img span {
      font-size: 14px;
      margin-top: -1px;
    }
    .header-right .user-profile p {
      font-size: 13px;
    }
    #signin-popup .medel-head figure, #signup-popup .medel-head figure{
      margin: -15px 0 5px;
    }
    #signin-popup .medel-head figure img, #signup-popup .medel-head figure img{
      max-height: 30px;
    }
    .login-list {
      margin: 10px 0;
    }
    .login-list li {
      margin: 4px;
    }
    .login-list li a {
      font-size: 15px;
      width: 30px;
      height: 30px;
  }
    .already-signup span {
      margin-top: 0;
      font-size: 17px;
    }
    .or-text strong{
      font-size: 18px;
    }
    .login-form {
      margin: 10px 0 10px;
    }
  }
.footer-top{
  background: #fff;
  border-top: 1px solid #edeeef;
}
.footer-top-inner{
  max-width: 800px;
  margin: 0 auto;
}
.footer-top h3{
  text-align: center;
  max-width: 600px;
  margin: 0 auto 60px;
  color: #333;
}
.footer-top h4{
  color: #0385c7;
  font-size: 14px;
}
.footer-business{
  border-top: 1px solid #edeeef;
  padding-top: 40px;
}
.footer-business .footer-menu{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.footer-business .footer-menu li{
  width: 33.33%;
  padding: 0 15px 0 0;
}
.footer-menu-outer{
  border-right: 1px solid #edeeef;
    width: 200px;
    margin-bottom: 50px;
    height: calc(100% - 50px);
}
.footer-top-inner .row .col-md-4:nth-child(3n) .footer-menu-outer{
  border-right: none;
}
.footer-menu li a{
  font-size: 14px;
  color: #707070;
  margin: 5px 0;
  display: inline-block;
}
.footer-menu li a:hover{
  color: #0385c7;
}
.footer-bottom {
  background: #0385c7;
  padding: 40px 0;
  color: #fff;
}
.footer-logo img{
  max-height: 32px;
}
.footer-comparison p{
  font-size: 12px;
}
.footer-comparison p span{
  width: 100%;
  display: block;
}
.footer-comparison p a{
  color: #fff;
  clear: both;
}
.footer-comparison p a:hover{
  text-decoration: underline;
}
.compa-link{
  border: 1px solid #fff;
  font-size: 12px;
  padding: 8px 20px;
  color: #fff;
  float: right;
}
.compa-link:hover{
  background: #fff;
  color: #0385c7;
}
.footer-socials{
  text-align: center;
}
.footer-socials a{
  color: #fff;
  margin: 15px;
  display: inline-block;
  font-size: 18px;
  transition: all .3s ease;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.footer-socials a:hover{
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
}
.copy-right{
  text-align: center;
}
.copy-right p{
  font-size: 9px;
}

@media screen and (max-width: 767px) {
  .footer-menu-outer {
    width: 100%;
    padding-right: 15px;
  }
  .footer-business .footer-menu li{
    width: 50%;
  }
  .footer-top-inner .row .col-md-4:nth-child(3n) .footer-menu-outer{
    border-right: 1px solid #edeeef;
  }
  .footer-top-inner .row .col-sm-6:nth-child(2n) .footer-menu-outer{
    border-right: none;
  }
  .compa-link {
    float: none;
    display: inline-block;
    margin-top: 10px;
  }
}
@media screen and (max-width: 575px) {
  .footer-top h3{
    margin: 0 auto 20px;
  }
  .footer-menu-outer {
    border: none!important;
    border-bottom: 1px solid #edeeef!important;
    width: 100%;
    margin-bottom: 10px;
    padding-right: 0;
    padding-bottom: 20px;
    height: calc(100% - 20px);
    text-align: center;
  }
  .footer-business {
    border-top: none;
    padding-top: 0px;
    text-align: center;
  }
  .footer-business .footer-menu li{
    width: 100%;
    padding: 0;
  }
  .footer-bottom{
    text-align: center;
    padding: 20px 0;
  }
  .footer-bottom .text-right{
    text-align: center!important;
  }
  .footer-bottom .logo{
    display: inline-block;
    margin-bottom: 20px;
  }
  .footer-comparison{
    margin: 10px 0;
  }
}
.banner{
    background: #e9ecef;
}
.banner-inner{
    min-height: 360px;
    align-items: center;
}
.banner-left{
    padding-top: 30px;
    padding-bottom: 80px;
}
.banner-left h1{
    font-size: 36px;
    color: #0385c7;
    font-weight: 700;
    margin: 0 0 25px 0;
}
.banner-left h1 span{
    color: #0385c7;
    text-transform: capitalize;
}
.banner-left p{
    font-size: 21px;
    line-height: 1.2;
    color: #707070;
}
.banner-right{
    min-height: 40px;
}
.banner-right img{
    max-height: 361px;
}
.lazy-banner-img{
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 385px;
    margin: 0 auto;
}
@media screen and (min-width: 1200px) {
    .lazy-banner-img{
        min-height: 361px;
    }
}
@media screen and (max-width: 1199px) {
    .lazy-banner-img{
        min-height: 260px;
    }
}
@media screen and (max-width: 767px) {
    .banner-inner{
        min-height: 100px;
    }
    /* 30/11/2022 */
    .banner-inner .lazy-banner-img{
        min-height: 200px;
    }
    .banner-inner .lazy-banner-img span{
        display: flex!important;
        position: relative;
        height: 200px;
        overflow: hidden;
    }
    .banner-inner .lazy-banner-img span img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
    }
    /*  */
    .banner-left{
        text-align: center;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .banner-left h1 {
        font-size: 30px;
        margin: 0 0 15px 0;
    }
    .banner-right{
        text-align: center;
    }
}
.city-outer{
    background: #f4f5f7;
}
.city-outer.white-bg{
    background: #fff;
}
.city-box{
    background: #0385c7;
    border-radius: 20px;
    padding: 40px 20px;
    margin: -60px auto 0;
    position: relative;
    border: 1px solid #0385c7;
    display: flex;
    align-items: center;
}
.city-box .input-outer{
    margin: 0 20px;
    flex: 1 1;
}
.city-box .input-outer .form-control{
    padding-left: 175px;
    padding-right: 62px;
    /* border-color: #b3daee; */
    border: none;
}
.city-box .input-outer .submit{
    background: #49c499;
    padding: 2px 5px!important;
    width: 55px;
    font-size: 22px;
    font-weight: 100;
    line-height: 1;
    right: -2px;
}
.city-box .input-outer .submit:hover{
    background: #239c72;
}
.city-box .input-outer .location-icon{
    position: absolute;
    top: 12px;
    left: 150px;
    color: #e76f6f;
    font-size: 17px;
}
.pincode{
    position: absolute;
    width: 140px;
    height: calc(100% - 14px);
    top: 6px;
    bottom: 0;
    line-height: 32px;
    padding: 0 15px;
    letter-spacing: 10px;
    color: #000;
    font-weight: bold;
    border-right: 1px solid #ccc;
    cursor: default;
}
.city-location{
    font-size: 15px;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #fff;
    text-transform: capitalize;
    letter-spacing: 1px;
    margin: 0;
    min-width: 100px;
    text-align: center;
}
.already-login{
    font-size: 12px;
    color: #fff;
}
.already-login .login{
    color: #fff;
    font-weight: bold;
    padding: 0;
    margin: 0 0 0 3px;
    border: none;
    background: none;
} 
.already-login .login:hover{
    text-decoration: underline;
}
@media screen and (max-width: 991px) {
    .city-box .input-outer .location-icon {
        left: 127px;
    }
    .pincode {
        width: 120px;
        letter-spacing: 4px;
    }
    .city-box .input-outer .form-control {
        padding-left: 147px;
    }    
}

@media screen and (max-width: 767px) {
    .city-box .input-outer .location-icon {
        left: 64px;
        font-size: 13px;
        top: 14px;
    }
    .pincode {
        width: 60px;
        letter-spacing: 1px;
        padding: 0 10px;
        font-size: 11px;
    }
    .city-box .input-outer .form-control {
        padding-left: 80px;
        font-size: 11px;
    }   
    .city-box{
        flex-direction: column;
        border-radius: 10px;
        margin: -40px 0 0 0;
        padding: 15px;
    }
    .city-box .input-outer{
        width: 100%;
        margin: 5px 0;
    }
    .already-login {
        right: 14px;
        bottom: 8px;
    }
}
.compare-outer{
    /* padding: 55px 0 80px; */
    padding: 80px 0 80px;
    background: #f4f5f7;
}
.compare-box{
    display: flex;
    flex-wrap: wrap;
    max-width: 930px;
    margin: 30px auto 0;
}
.compare-box li{
    width: calc(14.25% - 8px);
    margin: 4px;
}
.compare-box li a{
    background: #ffffff;
    border-radius: 8px;
    text-align: center;
    padding: 10px;
    border: 1px solid #eeeff0;
    display: block;
    transition: all .3s ease;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}
.compare-box li a:hover{
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
}
.compare-box li .img{
    margin: 20px 0 15px;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.compare-box li .img img{
    max-height: 100%;
}
.compare-box li a span{
    color: #4d4d4d;
    font-size: 17px;
}
.compare-outer .head{
    margin-top: 60px;
}
@media screen and (max-width: 767px) {
    .compare-outer {
        padding: 20px 0 40px 0;
    }
    .compare-box{
        margin-top: 0;
        justify-content: center;
    }
    .compare-box li {
        width: calc(50% - 8px);
    }
    .compare-outer .head {
        margin-top: 20px;
    }
}
.tcc-feature-left img{
    margin-top: -35px;
}
.tcc-feature-list{
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0;
    align-items: center;
    height: 100%;
}
.tcc-feature-list li{
    width: 50%;
    padding: 20px;
    display: flex;
}
.tcc-feature-list li .img{
    width: 25px;
    margin-right: 10px;
}
.tcc-feature-list li .img img{
    max-width: 22px;
}
.tcc-feature-list li .tcc-feature-text{
    flex: 1 1;
}
.tcc-feature-list li .tcc-feature-text h4{
    font-size: 18px;
    color: #333;
}
@media screen and (max-width: 991px) {
    .tcc-feature-list {
        padding: 30px 0 0 0;
    }
    .tcc-feature-list li{
        padding: 10px;
    }
}
@media screen and (max-width: 767px) {
    .tcc-feature-left{
        text-align: center;
    }
    .tcc-feature-list {
        padding: 0 0 30px 0;
    }
    .tcc-feature-list li{
        width: 100%;
        padding: 10px 0;
    }
    .tcc-feature-left img {
        max-height: 250px;
    }
}

.best-deals{
    background: #f4f5f7;
}
.best-deals .heading1{
    margin-bottom: 50px;
}
.deal-img{
    margin: 10px 0;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
}
@media screen and (min-width: 1200px) {
    .deal-img{
        min-height: 169px;
    }
}
.deal-img img{
    border-radius: 10px;
}
.best-deals .react-tabs {
    margin: 10px 0;
    /* min-height: calc(100% - 20px); */
    min-height: 353px;
}
.best-deals .box-switch .col-md-6:first-child{
    order: 1;
}
@media screen and (max-width: 1199px) {
    .best-deals .react-tabs{
        min-height: calc(100% - 20px);
    }
}
@media screen and (max-width: 767px) {
    .best-deals .box-switch .col-md-6:first-child{
        order: unset;
    }
}
.recent-comparison{
    padding: 35px 0 50px;
    background: #212121;
}
.recent-comparison .heading1{
    color: #fff;
}
.recent-comparison .sub-heading-1{
    text-align: center;
    color: #ededed;
    font-size: 16px;
    line-height: 1.2;
    margin: 0 0 25px;
}
.recent-comparison .col-lg-2 {
    flex: 0 0 auto;
    width: 20%;
}
.recent-box{
    background: #ffffff;
    border-radius: 8px;
    padding: 20px;
    margin: 15px 0;
    height: calc(100% - 30px);
}
.recent-box strong{
    display: block;
    font-size: 25px;
    padding: 5px 0 0 0;
    color: #1cb580;
}
.recent-box .signin{
    display: inline-block;
    font-size: 25px;
    padding: 5px 0 0 0;
    margin: 0;
    background: none;
    border: none;
    color: #1cb580;
    font-weight: bold;
    text-decoration: underline;
}
.recent-box .signin:hover{
    text-decoration: none;
}
.recent-box span{
    clear: both;
    display: block;
    padding-bottom: 20px;
    min-height: 42px;
}
@media screen and (max-width: 1199px) {
    .recent-box{
        padding: 20px 10px;
    }
    .recent-box .button{
        min-width: 100%;
    }
}
@media screen and (max-width: 767px) {
    .recent-comparison .col-lg-2 {
        width: 50%;
    }
    .recent-box{
        padding: 20px;
        text-align: center;
        max-width: 300px;
        margin: 0 auto 20px auto;
        height: calc(100% - 20px);
    }
}
.renowned-outer{
    padding: 50px 0;
}
.renowned-outer .heading2{
    color: #333;
}
.renowned-list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 40px -10px 0;
}
.renowned-list li{
    margin: 10px;
    width: 10%;
}
@media screen and (max-width: 767px) {
    .renowned-outer{
        padding: 30px 0;
    }
    .renowned-list{
        margin: 0;
        justify-content: center;
    }
    .renowned-list li {
        width: 18%;
    }
}
.testimonial-outer{
    padding: 10px 0 60px;
    overflow: hidden;
}
.testimonial-slider .slick-list{
    margin: 0 -15px;
}
.testimonial-slider .slick-list .slick-slide .item{
    padding: 0 15px;
    margin: 0 0 20px 0;
}
.testimonial-slider .slick-next,.testimonial-slider .slick-prev{
    display: none!important;
}
.testimonial-box{
    display: flex!important;
    background: #f4f5f7;
    padding: 20px;
    border-radius: 15px;
}
.testimonial-box .img{
    width: 80px;
    height: 80px;
    margin-right: 30px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
}
.testimonial-box .testimonial-text{
    flex: 1 1;
}
.testimonial-box .testimonial-text strong{
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    color: #333;
}
.testimonial-box .testimonial-text span{
    display: block;
    font-size: 14px;
    font-style: italic;
}
@media screen and (max-width: 1199px) {
    .testimonial-outer{
        padding-bottom: 10px;
    }
    .testimonial-outer .slick-dots{
        display: none!important;
    }
}
@media screen and (max-width: 575px) {
    .testimonial-box{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;   
    }
}
.grap-new-offer{
    background: #cde7f4;
}
.grap-new-left{
    padding-top: 80px;
    padding-bottom: 55px;
}
.grap-new-left .head{
    max-width: 445px;
    margin: 0 auto;
}
.grap-new-left h3{
    color: #202833;
    font-size: 22px;
    line-height: 1.3;
}
.grap-new-right img{
    margin-top: -35px;
}
@media screen and (max-width: 1199px) {
    .grap-new-left {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}
@media screen and (max-width: 991px) {
    .grap-new-right{
        display: none;
    }
}


.services-list li{
    display: flex;
    margin-bottom: 40px;
}
.services-list li .img{
    margin-left: 25px;
    width: 120px;
    height: 113px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
}
.services-list li .img img{
    max-height: 100%;
    border-radius: 5px;
}
.services-list-left{
    border-bottom: 1px solid #cccccc;
    padding-left: 50px;
    position: relative;
    flex: 1 1;
}
.services-list-left .img-icon{
    position: absolute;
    left: 0;
    max-width: 32px;
    max-height: 32px;
}
.services-list-left h4{
    font-size: 24px;
    margin-bottom: 22px;
}
.services-list-left p{
    margin-bottom: 22px;
}
.services-list-left a:hover{
    color: #0385c7;
    text-decoration: underline;
}
@media screen and (max-width: 767px) {
    .services-list li {
        margin-bottom: 20px;
        flex-direction: column;
    }
    .services-list li .img {
        margin: 0 auto 25px;
        order: -1;
        text-align: center;
    }
    .services-list-left .img-icon {
        max-width: 25px;
        max-height: 25px;
    }
    .services-list-left {
        padding-left: 40px;
    }
    .services-list-left h4 {
        font-size: 22px;
        margin-bottom: 10px;
    }
}
.adds{
    border: 1px solid #edeeef;
    background: #fff;
    border-radius: 8px;;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.related-articles{
    padding: 20px;
    border: 1px solid #edeeef;
    background: #fff;
    border-radius: 8px;;
}
.related-articles h3{
    font-size: 18px;
    color: #0385c7;
}
.related-article-list{
    margin: 25px 0 0 0;
}
.related-article-list li a{
    display: flex;
    margin: 0 0 25px 0;
}
.related-article-list li a:hover .text p{
    color: #0385c7;
}
.related-article-list li .img{
    width: 50px;
    height: 50px;
    margin-right: 10px;
    overflow: hidden;
    background: #ccc;
    display: flex;
    align-items: center;
    position: relative;
}
.related-article-list li .img img{
    max-width: 120px;
    max-height: 120px;
    overflow: hidden;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    width: 150%;
    position: absolute;
    top: 50%;
    left: 50%;
}
.related-article-list li .text{
    flex: 1 1;
}
.related-article-list li .text p{
    font-size: 14px;
    color: #414b5b;
    line-height: 1.3;
}

.result-not-found {
  display: flex;
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  padding: 25px;
  min-height: 125px;
}
@media screen and (max-width: 767px) {
  .result-not-found-inner {
    flex-direction: column;
    text-align: center;
  }
}
.compare-table-outer.mobile-view{
    display: none!important;
}
.compare-table th{
    padding: 20px 15px;
    text-align: center;
    border-bottom: 1px solid #b3b3b3;
}
.compare-table td{
    font-size: 15px;
    line-height: 1.3;
    border-right: 1px solid #b3b3b3;
    padding: 20px 15px;
    text-align: center;
    min-width: 150px;
    max-width: 150px;
}
.compare-table tr:nth-child(odd) {
    background: #f4f5f7;
}
.compare-table tr:nth-child(odd):first-child {
    background: none;
}
.compare-table td:first-child{
    border-left: 1px solid #b3b3b3;
    background: #1cb580;
    color: #fff;
    min-width: 250px;
    max-width: 250px;
    text-align: left;
    padding-left: 40px;
}
.compare-table td.drak-gray{
    background: #4d4d4d;
}
.compare-table tr:nth-child(odd) td:first-child{
    background: #1b9b6a;
}
.compare-table tr:last-child{
    background: none;
}
.compare-table tr.gray-bg{
    background: #b3b3b3!important;
}
.compare-table tr.gray-bg td{
    background: none!important;
    padding: 0;
}
.compare-table tr.gray-bg td span{
    color: #fff;
    position: relative;
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 15px;
    cursor: pointer;
}
.compare-table tr.gray-bg td span:hover{
    text-decoration: none;
    background: #999999;
}
.compare-table tr.gray-bg td span::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    /*right: 0;*/
    top: 50%;
    margin-top: -4px;
    margin-left: 8px;
    border-right: 2px solid;
    border-bottom: 2px solid;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s, -webkit-transform .3s;
}
.compare-table tr.gray-bg td span.active::after {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
    margin-top: 0;
}
.compare-table tr:last-child td{
    border-top: 1px solid #b3b3b3;
    border-left: none;
    border-right: none;
    background: #fff!important;
}
.compare-table tr td .m-box{
    padding: 20px 0;
    text-align: left;
    position: relative;
}
.compare-table tr td .m-box small{
    position: absolute;
    top: -2px;
    left: 0;
    text-decoration: line-through;
    color: #ff5722;
}
.compare-table tr td .m-box strong{
    display: block;
    font-size: 19px;
    line-height: 1.2;
    color: #1cb580;
    padding: 0;
}
.compare-table tr td .m-box .signin{
    display: inline-block;
    font-size: 21px;
    line-height: 1.2;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    color: #1cb580;
    font-weight: bold;
    text-decoration: underline;
}
.compare-table tr td .m-box .signin:hover{
    text-decoration: none;
}
.compare-table tr td .m-box span{
    font-size: 13px;
    padding: 6px 0 20px;
    clear: both;
    display: block;
}
.rating-icons{
    display: inline-flex;
    height: 21px;
    width: 106px;
}
/* popover css here */
.tc-popover-wrap{
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: 20px auto;
    cursor: pointer;
}
.utf-no,.utf-yes,.utf-notapplicable{
    display: inline-block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: 20px auto;
}
.tc-popover{
    position: absolute;
    left: 50%;
    bottom: 30px;
    margin: 0 0 0 -140px;
    width: 280px;
    display: none;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    border-radius:5px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    box-shadow: 0 0 8px rgba(0,0,0,.15)\9;
    z-index: 9;
}
.tc-popover::before {
    content: '';
    position: absolute;
    display: block;    
    width: 0px;        
    left: 50%;
    bottom: 5px;
    border: 10px solid transparent;
    border-bottom: 0;
    border-top: 10px solid #fff;
    -webkit-transform: translate(-50%, calc(100% + 5px));
            transform: translate(-50%, calc(100% + 5px));
  }
.tc-popover h6{
    padding:10px;
    border-radius: 5px 5px 0 0;
    background:#1cb580;
    color: #fff;
    border-bottom: 1px solid #ddd;
}
.tc-popover ul{
    padding:20px 10px; 
    margin-left: 20px;
}
.tc-popover ul li{
    list-style: disc; 
    padding: 0;
    margin: 0 0 3px;
    font-size: 14px;
}
.tc-popover-wrap:hover .tc-popover{
    display: block;
}
@media screen and (max-width: 1199px) {
    .compare-table-outer .offset-lg-1{
        width: 100%!important;
        margin: 0!important;
    }
    .compare-table tr.gray-bg td{
        padding: 0!important;
    }
    .compare-table tr.gray-bg td a{
        padding: 10px;
        padding-right: 15px;
    }
    .compare-table th {
        padding: 10px;
    }
    .compare-table td{
        padding: 10px!important;
        min-width: 120px;
        max-width: 120px;
    }
    .compare-table td:first-child {
        min-width: 140px;
        max-width: 140px;
    }
    .compare-table tr:last-child td .button{
        width: 100%;
        min-width: 100%;
    }
}
@media screen and (max-width: 991px) {
    .compare-table{
        margin-bottom: 0;
    }
    .compare-table th {
        padding: 0 10px 10px 10px;
    }
    .compare-table tr td .m-box{
        text-align: center;
    }
    .compare-table tr td .m-box strong {
        font-size: 18px;
    }
}
@media screen and (max-width: 767px) {
    .compare-table-outer .offset-lg-1{
        margin: 0 12px!important;
        padding: 0 !important;
        width: calc(100% - 24px);
        max-width: calc(100% - 24px);
        overflow: auto;
    }
    .compare-table tr td:first-child,.compare-table th:first-child{
        position: -webkit-sticky;
        position: sticky;
        left: -1px;
        z-index: 9;
    }
    .compare-table tr:last-child td:first-child,.compare-table tr:first-child th:first-child{
        background: #fff;
    }
    .compare-table td {
        font-size: 13px;
        min-width: 160px;
        max-width: 160px;
    }
}

.comparisons-list li{
    padding-bottom: 70px;
}
.comparisons-list li:last-child{
    padding-bottom: 0;
}
.comparisons-list li .img{
    margin-bottom: 20px;
}
.comparisons-list li .cl-img{
    background-repeat: no-repeat;
    background-size: cover;
}
@media screen and (min-width: 1200px) {
    .comparisons-list li .cl-img{
        min-height: 400px;
    }
}
@media screen and (max-width: 1199px) {
    .comparisons-list li .cl-img{
        min-height: 160px;
    }
}

.comparisons-list li .img img{
    width: 100%;
}
.comparisons-list li h4{
    font-size: 20px;
    color: #0385c7;
}
.top-company-box{
    border-radius: 15px;
    padding: 20px;
    margin: 20px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #dceef7;
    background: #f6fbfd;
}
.top-company-box p{
    padding: 0 10px;
    margin: 0;
}
.top-company-box strong{
    display: inline-block;
    margin: 0 5px 0 0;
    color: #1cb580;
    font-size: 19px;
}
.top-company-box .signin{
    display: inline-block;
    margin: 0;
    background: none;
    border: none;
    color: #1cb580;
    margin: 0 5px 0 0;
    font-weight: bold;
    text-decoration: underline;
}
.top-company-box .signin:hover{
    text-decoration: none;
}
@media screen and (max-width: 767px) {
    .comparisons-list li {
        padding-bottom: 30px;
    }
    .top-company-box {
        flex-direction: column;
        padding: 15px;
    }
    .top-company-box p {
        padding: 10px 0;
    }
}
.rank-sec h3{
    font-size: 36px;
    margin-bottom: 25px;
}
.rank-sec h4{
    font-size: 24px;
    margin-bottom: 25px;
}
.rank-sec p{
    margin-bottom: 25px;
}
@media screen and (max-width: 767px) {
    .rank-sec{
        text-align: left;
    }
    .rank-sec h3{
        font-size: 22px;
        margin-bottom: 10px;
    }
    .rank-sec h4 {
        font-size: 18px;
        margin-bottom: 10px;
    }
}

.company-feature h3{
    font-size: 36px;
    margin-bottom: 20px;
    font-weight: 600;
}
.company-feature h4{
    font-size: 24px;
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: 600;
}
.company-feature h4:first-child{
    margin-top: 0;
}
.company-feature p{
    margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
    .company-feature h4 {
        font-size: 20px;
        margin-top: 30px;
        margin-bottom: 10px;
    }
}
.monthly-price{
    padding: 20px 30px;
    border: 1px solid #edeeef;
    background: #fff;
    border-radius: 8px;;
    overflow: hidden;
}
.monthly-price strong{
    display: block;
    font-size: 26px;
    color: #1cb580;
}
.monthly-price .signin {
    display: inline-block;
    font-size: 25px;
    padding: 5px 0 0 0;
    margin: 0;
    background: none;
    border: none;
    color: #1cb580;
    font-weight: bold;
    text-decoration: underline;
}
.monthly-price .signin:hover {
    text-decoration: none;
}
.monthly-price span{
    font-size: 18px;
    clear: both;
    display: block;
    padding-bottom: 20px;
}
@media screen and (max-width: 767px) {
    .monthly-price{
        text-align: center;
    }
}
.edit-profilt{
    padding: 70px 0;
    background: #ebf1f6;
    min-height: 285px;
}
.user-profile{
    display: flex;
    align-items: center;
}
.user-profile .user-img{
    width: 135px;
    height: 135px;
    background: #009ee2;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid #fff;
    margin: 0 22px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.user-profile .user-img img{
    width: 130%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    max-width: none;
}
.user-profile .user-img span{
    color: #fff;
    font-size: 70px;
    line-height: 1;
    display: inline-block;
    margin-top: -4px;
    text-transform: uppercase;
}
.user-profile p{
    flex: 1 1;
    font-size: 20px;
    line-height: 1.2;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.user-profile .edit-pic{
    display: none;
}
.user-profile .upload-button{
    display: none;
}
.edit-button{
    border-radius: 5px;
    padding: 4px 10px;
    min-width: 100px;
    position: absolute;
    right: 0;
    bottom: -20px;
}
.edit-button .camra{
    margin: -3px 5px 0 0;
    display: inline-block;
}
.profile-right{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
@media screen and (max-width: 767px) {
    .edit-profilt{
        padding: 30px 0;
        min-height: 100px;
    }
    .user-profile .user-img {
        width: 80px;
        height: 80px;
        margin: 0 10px 0 0;
    }
    .user-profile .user-img span {
        font-size: 40px;
    }
}
.stay-tune{
    padding: 130px 0;
    text-align: center;
}
.stay-tune p{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
}
.stay-tune span{
    margin-left: 10px;
}
@media screen and (max-width: 767px) {
    .stay-tune{
        padding: 50px 0;
    }
    .stay-tune p{
        flex-direction: column;
    }
    .stay-tune span{
        margin: 20px 0 0 0;
    }
}
/*react-responsive-modal-modal*/
#profile-popup{
    padding: 0;
    margin: 15px;
    width: calc(100% - 30px);
    max-width: 600px;
    border-radius: 8px;
}
#profile-popup h3{
    margin: 0;
    padding: 20px 20px 12px;
}
#profile-popup .edit-profilt{
    min-height: 100px;
    padding: 50px;
}
#profile-popup .edit-profilt .user-profile p{
    display: none;
}
#profile-popup .edit-profilt .edit-button{
    display: none;
}
#profile-popup .edit-profilt .user-profile .upload-button{
    display: block;
}
#profile-popup .profile-form{
    padding: 40px;
}
#profile-popup .profile-form .error{
    position: relative;
}
#profile-popup .profile-form label{
    display: block;
    font-size: 12px;
    color: #c2c2c2;
}
#profile-popup .profile-form .form-control{
    display: block;
    font-size: 12px;
    color: #999;
}
#profile-popup .profile-form .btn-outer{
    text-align: right;
}
#profile-popup .profile-form .btn-outer .button{
    margin-left: 10px;
    border-radius: 5px;
}
#profile-popup .react-responsive-modal-closeButton{
    display: none;
}
#profile-popup .user-profile .edit-pic{
    position: absolute;
    width: 45px;
    height: 45px;
    background: rgba(0, 0, 0, 0.7);
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
#profile-popup .user-profile .edit-pic:hover{
    background: rgba(0, 0, 0, 0.8);
}
#profile-popup .user-profile .edit-pic input[type="file"]{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 9;
}
#profile-popup .user-profile .edit-pic .camra{
    color: #fff;
}
@media screen and (max-width: 767px) {
    #profile-popup .edit-profilt {
        min-height: 100px;
        padding: 40px 10px;
    }
    #profile-popup .profile-form {
        padding: 20px;
    }
    #profile-popup .profile-form .btn-outer {
        text-align: center;
    }
    #profile-popup .profile-form .btn-outer .button {
        margin: 5px;
    }
}

.pageNotFoundContent .row{
    align-items: center;
}
@media screen and (max-width: 767px) {
    .pageNotFoundContent .row{
        text-align: center;
    }
    .pageNotFoundContent .row h3{
        margin-top: 30px;
        font-size: 18px;
    }
}

.blog-search-outer{
    background: #f4f5f7;
}
.blog-search-outer.white-bg{
    background: #fff;
}
.blog-box{
    background: #0385c7;
    border-radius: 20px;
    padding: 40px 20px;
    margin: -60px auto 0;
    position: relative;
    border: 1px solid #edeeef;
    display: flex;
    align-items: center;
}
.blog-box .input-outer{
    margin: 0 20px;
    flex: 1 1;
}
.blog-box .input-outer .form-control{
    padding-right: 62px;
    /* border-color: #b3daee; */
    border: none;
}
.blog-box .input-outer .submit{
    background: #49c499;
    padding: 2px 5px;
    width: 55px;
    font-size: 22px;
    font-weight: 100;
    line-height: 1;
    right: -2px;
}
.blog-box .input-outer .submit:hover{
    background: #239c72;
}
@media screen and (max-width: 767px) {
    .blog-box{
        flex-direction: column;
        border-radius: 10px;
        margin: -40px 0 0 0;
        padding: 15px;
    }
    .blog-box .input-outer{
        width: 100%;
        margin: 0;
    }
}
.blog-list{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}
.blog-list li{
    width: calc(33.33% - 20px);
    margin: 0 10px 20px 10px;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 10px #ccc;
    transition: all .3s ease;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}
.blog-list li .blog-list-img{
    background-repeat: no-repeat;
    background-size: cover;
}
@media screen and (min-width: 1200px) {
    .blog-list li .blog-list-img{
        min-height: 160px;
    }
}
@media screen and (max-width: 1199px) {
    .blog-list li .blog-list-img{
        min-height: 140px;
    }
}

.blog-list li:hover{
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
}
.blog-list li .text{
    padding: 20px;
}
.blog-list li .text h4{
    font-size: 18px;
    color: #0385c7;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 10px;
}
.blog-list li .text p{
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #333;
}
@media screen and (max-width: 991px) {
    .blog-list li {
        width: calc(50% - 20px);
    }
}
@media screen and (max-width: 575px) {
    .blog-list li {
        width: calc(100% - 20px);
    }
}
.categories{
    padding: 20px;
    border: 1px solid #edeeef;
    background: #fff;
    border-radius: 8px;;
}
.categories h3{
    font-size: 18px;
    color: #0385c7;
}
.category-list li a{
    font-size: 12px;
    color: #666666;
    margin: 5px 0;
    display: inline-block;
}
.category-list li a:hover{
    text-decoration: underline;
}



.blog-detail-list h2,.blog-detail-list h3,.blog-detail-list h4,.blog-detail-list p{
    margin-bottom: 20px;
}
.blog-detail-list .img{
    margin-bottom: 20px;
}
.blog-detail-list .img img{
    width: 100%;
}
.blog-detail-list h3{
    font-size: 24px;
}

.blog-detail-list .blog-d-img{
    background-repeat: no-repeat;
    background-size: cover;
}
ul.tcc-list-style li{
    margin-bottom: 15px;
    position: relative;
    padding: 0 0 0 15px;
}
ul.tcc-list-style li::before{
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #707070;
    left: 0;
    top: 7px;
    content: '';
}
@media screen and (min-width: 1200px) {
    .blog-detail-list .blog-d-img{
        min-height: 500px;
    }
    .blog-detail-list .blog-d-img .lazy-load-image-background{
        width: 100%;
    }
}
@media screen and (max-width: 1199px) {
    .blog-detail-list .blog-d-img{
        min-height: 220px;
    }
}

.pageNotFoundContent .row{
    align-items: center;
}
@media screen and (max-width: 767px) {
    .pageNotFoundContent .row{
        text-align: center;
    }
    .pageNotFoundContent .row h3{
        margin-top: 30px;
        font-size: 18px;
    }
}

.pageNotFoundContent .row{
    align-items: center;
}
@media screen and (max-width: 767px) {
    .pageNotFoundContent .row{
        text-align: center;
    }
    .pageNotFoundContent .row h3{
        margin-top: 30px;
        font-size: 18px;
    }
}

.contact-map{
    border-radius: 5px;
    box-shadow: 0 0 10px #ccc;
    overflow: hidden;
}
.contact-form{
    background: #ebf1f6;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px #ccc;
}
.contact-form label{
    color: #999;
}
.contact-form .btn-outer{
    text-align: center;
}

.partner-form{
    background: #ebf1f6;
    padding: 20px;
    border-radius: 5px;
    /* box-shadow: 0 0 10px #ccc; */
    border: 1px solid #ced4da;
}
.partner-form label{
    color: #333;
    font-size: 14px;
}
.m-l-r-5 a{
    margin: 0 5px;
    display: inline-block;
}
.partner-form .btn-outer{
    text-align: center;
}
.hear-about{
    display: flex;
    align-items: center;
    padding: 20px;
    background: #1cb580;
}
.hear-about p{
    margin: 0 20px 0 0;
    color: #fff;
}
.hear-about select{
    flex: 1 1;
    min-width: 200px;
}
.hear-about select + .error{
    position: relative;
    margin: 0 0 0 5px!important
}
@media screen and (max-width: 767px) {
    .hear-about{
        flex-direction: column;
        align-items: flex-start;
        padding: 15px 10px;
    }
    .hear-about p {
        margin: 0 0 10px 0;
    }
}

.site-map h3{
    margin-bottom: 20px;
}
.site-map .footer-menu-outer{
    height: auto;
    width: 100%;
    border: none!important;
    margin-bottom: 20px;
}
@media screen and (max-width: 575px) {
    .site-map{
        text-align: center;
    }
    .site-map h4{
        font-size: 18px;
    }
}

@media screen and (max-width: 767px) {
    .about-content .about-content-img{
        order: -1;
        max-width: 280px;
        margin: 0 auto 20px;
    }
}
.about-benifits{
    background: #f4f5f7;
}
.about-benifits-list{
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0 0 0;
}
.about-benifits-list li{
    width: 50%;
    padding: 0 0 20px;
    display: flex;
}
.about-benifits-list li .img{
    width: 25px;
    margin-right: 10px;
}
.about-benifits-list li .img img{
    max-width: 22px;
}
.about-benifits-list li .about-benifits-text{
    flex: 1 1;
}
.about-benifits-list li .about-benifits-text h4{
    font-size: 18px;
}
@media screen and (max-width: 991px) {
    .about-benifits-list {
        padding: 30px 0 0 0;
    }
    .about-benifits-list li{
        padding: 10px;
    }
}
@media screen and (max-width: 767px) {
    h2{
        font-size: 25px;
    }
    h3{
        font-size: 20px;
    }
    .about-benifits-list {
        padding: 0 0 30px 0;
        text-align: left;
    }
    .about-benifits-list li{
        width: 100%;
        padding: 10px 0;
    }
}

@media screen and (max-width: 767px) {
    .about-mission .about-mission-img{
        order: -1;
        max-width: 200px;
        margin: 0 0 20px 50px;
    }
}
.d-top-strip .top-strip{
  display: block;
}
.top-strip{
  background: #f0c139;
  padding: 10px 0;
  text-align: center;
  width: 100%;
  display: none;
}
.top-strip p{
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top-strip p span{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}
.top-strip p a{
  text-decoration: underline;
  color: #0385c7;
  margin-left: 5px;
  white-space: nowrap;
}
.top-strip p a:hover{
  text-decoration: none;
}
@media screen and (max-width: 991px) {
  .top-strip{
    padding: 6px;
  }
}
