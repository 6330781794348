.comparisons-list li{
    padding-bottom: 70px;
}
.comparisons-list li:last-child{
    padding-bottom: 0;
}
.comparisons-list li .img{
    margin-bottom: 20px;
}
.comparisons-list li .cl-img{
    background-repeat: no-repeat;
    background-size: cover;
}
@media screen and (min-width: 1200px) {
    .comparisons-list li .cl-img{
        min-height: 400px;
    }
}
@media screen and (max-width: 1199px) {
    .comparisons-list li .cl-img{
        min-height: 160px;
    }
}

.comparisons-list li .img img{
    width: 100%;
}
.comparisons-list li h4{
    font-size: 20px;
    color: #0385c7;
}
.top-company-box{
    border-radius: 15px;
    padding: 20px;
    margin: 20px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #dceef7;
    background: #f6fbfd;
}
.top-company-box p{
    padding: 0 10px;
    margin: 0;
}
.top-company-box strong{
    display: inline-block;
    margin: 0 5px 0 0;
    color: #1cb580;
    font-size: 19px;
}
.top-company-box .signin{
    display: inline-block;
    margin: 0;
    background: none;
    border: none;
    color: #1cb580;
    margin: 0 5px 0 0;
    font-weight: bold;
    text-decoration: underline;
}
.top-company-box .signin:hover{
    text-decoration: none;
}
@media screen and (max-width: 767px) {
    .comparisons-list li {
        padding-bottom: 30px;
    }
    .top-company-box {
        flex-direction: column;
        padding: 15px;
    }
    .top-company-box p {
        padding: 10px 0;
    }
}