.pageNotFoundContent .row{
    align-items: center;
}
@media screen and (max-width: 767px) {
    .pageNotFoundContent .row{
        text-align: center;
    }
    .pageNotFoundContent .row h3{
        margin-top: 30px;
        font-size: 18px;
    }
}