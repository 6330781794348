.grap-new-offer{
    background: #cde7f4;
}
.grap-new-left{
    padding-top: 80px;
    padding-bottom: 55px;
}
.grap-new-left .head{
    max-width: 445px;
    margin: 0 auto;
}
.grap-new-left h3{
    color: #202833;
    font-size: 22px;
    line-height: 1.3;
}
.grap-new-right img{
    margin-top: -35px;
}
@media screen and (max-width: 1199px) {
    .grap-new-left {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}
@media screen and (max-width: 991px) {
    .grap-new-right{
        display: none;
    }
}