.result-not-found {
  display: flex;
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  padding: 25px;
  min-height: 125px;
}
@media screen and (max-width: 767px) {
  .result-not-found-inner {
    flex-direction: column;
    text-align: center;
  }
}