.renowned-outer{
    padding: 50px 0;
}
.renowned-outer .heading2{
    color: #333;
}
.renowned-list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 40px -10px 0;
}
.renowned-list li{
    margin: 10px;
    width: 10%;
}
@media screen and (max-width: 767px) {
    .renowned-outer{
        padding: 30px 0;
    }
    .renowned-list{
        margin: 0;
        justify-content: center;
    }
    .renowned-list li {
        width: 18%;
    }
}