.edit-profilt{
    padding: 70px 0;
    background: #ebf1f6;
    min-height: 285px;
}
.user-profile{
    display: flex;
    align-items: center;
}
.user-profile .user-img{
    width: 135px;
    height: 135px;
    background: #009ee2;
    border-radius: 50%;
    overflow: hidden;
    border: 4px solid #fff;
    margin: 0 22px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.user-profile .user-img img{
    width: 130%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: none;
}
.user-profile .user-img span{
    color: #fff;
    font-size: 70px;
    line-height: 1;
    display: inline-block;
    margin-top: -4px;
    text-transform: uppercase;
}
.user-profile p{
    flex: 1;
    font-size: 20px;
    line-height: 1.2;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.user-profile .edit-pic{
    display: none;
}
.user-profile .upload-button{
    display: none;
}
.edit-button{
    border-radius: 5px;
    padding: 4px 10px;
    min-width: 100px;
    position: absolute;
    right: 0;
    bottom: -20px;
}
.edit-button .camra{
    margin: -3px 5px 0 0;
    display: inline-block;
}
.profile-right{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
@media screen and (max-width: 767px) {
    .edit-profilt{
        padding: 30px 0;
        min-height: 100px;
    }
    .user-profile .user-img {
        width: 80px;
        height: 80px;
        margin: 0 10px 0 0;
    }
    .user-profile .user-img span {
        font-size: 40px;
    }
}