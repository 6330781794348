.services-list li{
    display: flex;
    margin-bottom: 40px;
}
.services-list li .img{
    margin-left: 25px;
    width: 120px;
    height: 113px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
}
.services-list li .img img{
    max-height: 100%;
    border-radius: 5px;
}
.services-list-left{
    border-bottom: 1px solid #cccccc;
    padding-left: 50px;
    position: relative;
    flex: 1;
}
.services-list-left .img-icon{
    position: absolute;
    left: 0;
    max-width: 32px;
    max-height: 32px;
}
.services-list-left h4{
    font-size: 24px;
    margin-bottom: 22px;
}
.services-list-left p{
    margin-bottom: 22px;
}
.services-list-left a:hover{
    color: #0385c7;
    text-decoration: underline;
}
@media screen and (max-width: 767px) {
    .services-list li {
        margin-bottom: 20px;
        flex-direction: column;
    }
    .services-list li .img {
        margin: 0 auto 25px;
        order: -1;
        text-align: center;
    }
    .services-list-left .img-icon {
        max-width: 25px;
        max-height: 25px;
    }
    .services-list-left {
        padding-left: 40px;
    }
    .services-list-left h4 {
        font-size: 22px;
        margin-bottom: 10px;
    }
}