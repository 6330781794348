.stay-tune{
    padding: 130px 0;
    text-align: center;
}
.stay-tune p{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
}
.stay-tune span{
    margin-left: 10px;
}
@media screen and (max-width: 767px) {
    .stay-tune{
        padding: 50px 0;
    }
    .stay-tune p{
        flex-direction: column;
    }
    .stay-tune span{
        margin: 20px 0 0 0;
    }
}