.compare-outer{
    /* padding: 55px 0 80px; */
    padding: 80px 0 80px;
    background: #f4f5f7;
}
.compare-box{
    display: flex;
    flex-wrap: wrap;
    max-width: 930px;
    margin: 30px auto 0;
}
.compare-box li{
    width: calc(14.25% - 8px);
    margin: 4px;
}
.compare-box li a{
    background: #ffffff;
    border-radius: 8px;
    text-align: center;
    padding: 10px;
    border: 1px solid #eeeff0;
    display: block;
    transition: all .3s ease;
    transform: translateY(0);
}
.compare-box li a:hover{
    transform: translateY(-5px);
}
.compare-box li .img{
    margin: 20px 0 15px;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.compare-box li .img img{
    max-height: 100%;
}
.compare-box li a span{
    color: #4d4d4d;
    font-size: 17px;
}
.compare-outer .head{
    margin-top: 60px;
}
@media screen and (max-width: 767px) {
    .compare-outer {
        padding: 20px 0 40px 0;
    }
    .compare-box{
        margin-top: 0;
        justify-content: center;
    }
    .compare-box li {
        width: calc(50% - 8px);
    }
    .compare-outer .head {
        margin-top: 20px;
    }
}