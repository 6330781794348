.partner-form{
    background: #ebf1f6;
    padding: 20px;
    border-radius: 5px;
    /* box-shadow: 0 0 10px #ccc; */
    border: 1px solid #ced4da;
}
.partner-form label{
    color: #333;
    font-size: 14px;
}
.m-l-r-5 a{
    margin: 0 5px;
    display: inline-block;
}
.partner-form .btn-outer{
    text-align: center;
}
.hear-about{
    display: flex;
    align-items: center;
    padding: 20px;
    background: #1cb580;
}
.hear-about p{
    margin: 0 20px 0 0;
    color: #fff;
}
.hear-about select{
    flex: 1;
    min-width: 200px;
}
.hear-about select + .error{
    position: relative;
    margin: 0 0 0 5px!important
}
@media screen and (max-width: 767px) {
    .hear-about{
        flex-direction: column;
        align-items: flex-start;
        padding: 15px 10px;
    }
    .hear-about p {
        margin: 0 0 10px 0;
    }
}