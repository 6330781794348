.blog-search-outer{
    background: #f4f5f7;
}
.blog-search-outer.white-bg{
    background: #fff;
}
.blog-box{
    background: #0385c7;
    border-radius: 20px;
    padding: 40px 20px;
    margin: -60px auto 0;
    position: relative;
    border: 1px solid #edeeef;
    display: flex;
    align-items: center;
}
.blog-box .input-outer{
    margin: 0 20px;
    flex: 1;
}
.blog-box .input-outer .form-control{
    padding-right: 62px;
    /* border-color: #b3daee; */
    border: none;
}
.blog-box .input-outer .submit{
    background: #49c499;
    padding: 2px 5px;
    width: 55px;
    font-size: 22px;
    font-weight: 100;
    line-height: 1;
    right: -2px;
}
.blog-box .input-outer .submit:hover{
    background: #239c72;
}
@media screen and (max-width: 767px) {
    .blog-box{
        flex-direction: column;
        border-radius: 10px;
        margin: -40px 0 0 0;
        padding: 15px;
    }
    .blog-box .input-outer{
        width: 100%;
        margin: 0;
    }
}