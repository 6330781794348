.related-articles{
    padding: 20px;
    border: 1px solid #edeeef;
    background: #fff;
    border-radius: 8px;;
}
.related-articles h3{
    font-size: 18px;
    color: #0385c7;
}
.related-article-list{
    margin: 25px 0 0 0;
}
.related-article-list li a{
    display: flex;
    margin: 0 0 25px 0;
}
.related-article-list li a:hover .text p{
    color: #0385c7;
}
.related-article-list li .img{
    width: 50px;
    height: 50px;
    margin-right: 10px;
    overflow: hidden;
    background: #ccc;
    display: flex;
    align-items: center;
    position: relative;
}
.related-article-list li .img img{
    max-width: 120px;
    max-height: 120px;
    overflow: hidden;
    transform: translate(-50%,-50%);
    width: 150%;
    position: absolute;
    top: 50%;
    left: 50%;
}
.related-article-list li .text{
    flex: 1;
}
.related-article-list li .text p{
    font-size: 14px;
    color: #414b5b;
    line-height: 1.3;
}